<h1 *ngIf="partnerOffers.length>0" mat-dialog-title>Доступные предложения цены</h1>
<div mat-dialog-content>
  <app-price-offers-view [offers]="offers" (selected)="selectOffer($event)"></app-price-offers-view>

  <div *ngFor="let po of partnerOffers">
  <hr>
    <h2><a [href]="getReference(po.partnerId)">{{po.partnerName}}</a></h2>
      <app-price-offers-view [offers]="po.offers" [showPartners]="false" (selected)="selectOffer($event)"></app-price-offers-view>
  </div>

  <label *ngIf="partnerOffers.length==0 && offers.length == 0">Нет предложений по выбранной позиции</label>
</div>
