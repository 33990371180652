<mat-table #offersTable [dataSource]="offers">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef> Наименование </mat-header-cell>
    <mat-cell *matCellDef="let offer"> {{offer.name}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="price">
    <mat-header-cell *matHeaderCellDef> Цена за тонну </mat-header-cell>
    <mat-cell *matCellDef="let offer">
      <a href="javascript:void(0)" (click)="editOffer(offer)">{{offer.price}}{{formatCurrency(offer.currency?.code)}} </a>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="validThru">
    <mat-header-cell *matHeaderCellDef> Действительна до </mat-header-cell>
    <mat-cell *matCellDef="let offer"> {{offer.validThru  | date:'mediumDate' }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let offer">
      <button *ngIf="currentSession.isAdmin" mat-mini-fab (click)="deleteOffer(offer)">
        <mat-icon>delete</mat-icon>
      </button> </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="['name', 'price', 'validThru', 'actions']"></mat-header-row>
  <mat-row *matRowDef="let row; columns: ['name', 'price', 'validThru', 'actions'];"></mat-row>

</mat-table>
<button mat-fab (click)="addOffers()"><mat-icon>add</mat-icon></button>
