
    <div class="form-group">

      <mat-slide-toggle  [(ngModel)]="user.isActive">Активный</mat-slide-toggle>
    </div>
    <div class="form-group">
      <mat-form-field>
       <input matInput [(ngModel)]="user.fullName" placeholder="Имя">
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
       <input matInput [(ngModel)]="user.eMail" placeholder="E-Mail">
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-slide-toggle [(ngModel)]="user.isAdmin">Администратор</mat-slide-toggle>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onClose()" cdkFocusInitial>Отмена</button>
      <button mat-button [mat-dialog-close]="user">OK</button>
    </div>
