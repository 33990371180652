import { Component, OnInit } from '@angular/core';
import { ShipmentsService } from '../services/shipments.service';
import { ShipmentModel } from '../models/shipment/shipment.model';
import { MatDialog } from '@angular/material/dialog';
import { EditShipmentComponent } from '../modals/edit-shipment/edit-shipment.component';
import { ConfirmDialogComponent } from '../modals/common.dialogs/confirm.dialog/confirm.dialog.component';
import { ShipmentPhotosModalComponent } from '../modals/shipment-photos-modal/shipment-photos-modal.component';
import { NamedObject } from '../models/named-object.model';
import { DictionariesService } from '../services/dictionaries.service';
import { DateService } from '../services/date.service';
import { PartnerShipmentAddressModel } from '../models/partners/partner-shipment-address.model';
import { PartnerService } from '../services/partner.service';
import { throttleTime } from 'rxjs/operators';
import { FactoryModel } from '../models/factory.model';
import { PartnerFactoryModel } from '../models/partners/partner-factory.model';
import { FactoryService } from '../services/factory.service';

@Component({
  templateUrl: './shipments.component.html',
  styleUrls: ['./shipments.component.scss']
})
export class ShipmentsComponent implements OnInit {

  shipments: ShipmentModel[] = [];

  dateFrom: Date;
  dateTo: Date;
  partnerId? : number;
  factoryId? : number;

  partners : NamedObject[] = [];
  factories: FactoryModel[] = [];

  constructor(private shipmentService: ShipmentsService, public dialog: MatDialog, private dictionaryService: DictionariesService,
    private dateService: DateService, private factoryService: FactoryService) { }

  ngOnInit(): void {

    this.dateFrom = this.dateService.getBeginOfMonth();
    this.dateTo = this.dateService.getEndOfMonth();

    this.dictionaryService.getBuyers().subscribe(result => {
      this.partners = [null, ... result];
    });

    this.factoryService.getFactories()
    .subscribe(result => {
      this.factories = [null, ... result];
    });

    this.reloadData();
  }

  public reloadData () {
    this.shipmentService.getShipments(this.dateFrom, this.dateTo, this.partnerId, this.factoryId)
    .pipe(throttleTime(500))
    .subscribe(result => {
      this.shipments = result;
    });
  }

  addShipment() : void {

    const dialogRef = this.dialog.open(EditShipmentComponent, {data: {shipmentDate: this.dateService.today(), comment: '', pictures: []}});

    dialogRef.afterClosed().subscribe(result => {

      if (result != null) {
        this.shipmentService.addShipment(result).subscribe(() => {
          this.reloadData();
        });
      }

    });
  }

  editShipment(id: number) {
    this.shipmentService.get(id).subscribe(shipment => {
      const dialogRef = this.dialog.open(EditShipmentComponent, {data: shipment});
      dialogRef.afterClosed().subscribe(result => {
        this.shipmentService.updateShipment(id, result).subscribe(updateResult => {
          this.reloadData();
        });
      });
    });
  }

  deleteShipment(id: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {data: {title: 'Удаление отгрузки',
    message: 'Вы уверены, что хотите удалить выбранную отгрузку? Все связанные с ней данные будут безвозвратно удалены!'}});

    dialogRef.afterClosed().subscribe(result => {
      if (result=='Y')
      {
        this.shipmentService.delete(id).subscribe(delResult => {
          this.shipments = this.shipments.filter(a => a.id != id);
        });
      }
    });
  }

  showPhotos(id: number) {
    const dialogRef = this.dialog.open(ShipmentPhotosModalComponent);

    dialogRef.afterClosed().subscribe();
  }

  formatDateTime (date: string) : string {
    let d = new Date(date);
    return d.toLocaleString();
  }

  partnerChanged(partnerId: number) {
    this.reloadData();
  }
}
