<div mat-dialog-content>
  <h1>Смена пароля</h1>
<div class="form-group">
  <mat-form-field>
   <input matInput [(ngModel)]="password" type="password" placeholder="Новый пароль">
  </mat-form-field>
</div>

<div class="form-group">
  <mat-form-field>
   <input matInput [(ngModel)]="passwordRepeat" type="password" placeholder="Повторите пароль">
  </mat-form-field>
</div>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onClose()" cdkFocusInitial>Отмена</button>
  <button mat-button [disabled]="!isOk()" [mat-dialog-close]="password">OK</button>
</div>

