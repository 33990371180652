import { Component, OnInit, Input, Output } from '@angular/core';
import { PartnerContact } from 'src/app/models/partner.model';
import { DictionariesService } from 'src/app/services/dictionaries.service';
import { ContactType } from 'src/app/models/partners/contact-type.model';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-contacts-editor',
  templateUrl: './contacts-editor.component.html',
  styleUrls: ['./contacts-editor.component.scss']
})
export class ContactsEditorComponent implements OnInit {

  constructor(private dictionariesService: DictionariesService, private dialogService: DialogService) { }

  contactTypes: ContactType[] = [];

  @Input()
  @Output()
  public contactData: PartnerContact[];

  ngOnInit(): void {
    this.dictionariesService.getContactTypes()
    .subscribe(result => {
      this.contactTypes = result;
    });
  }

  addContact(contactType: string) {
    this.dialogService.inputContact('', contactType, this.getContactType(contactType))
    .subscribe(result => {
      if (result != null) {
        this.contactData.push({contactType: contactType, contactData: result});
      }
    });
  }

  getContactType(contactTypeKey: string) : string {
    return this.contactTypes.find(a=>a.key == contactTypeKey)?.displayName;
  }

  editContact(contact: PartnerContact) {
    this.dialogService.inputContact(contact.contactData, contact.contactType, this.getContactType(contact.contactType))
    .subscribe(result => {
      if (result != null) {
        contact.contactData = result;
      }
    });
  }

  deleteContact(contact: PartnerContact) {
    this.dialogService.yesNoQuestion("Удалить контакт?")
    .subscribe(result => {
      if (result) {
        const index = this.contactData.indexOf(contact, 0);
        delete this.contactData[index];
        this.contactData.splice(index, 1);
      }
    });
  }

  getContactIcon(contactType: string) : string {
    switch (contactType) {
      case 'CellPhone':
        return 'cellphone';
      case 'WorkPhone':
        return 'phone';
      case 'Email':
        return 'at';
      case 'WhatsApp':
        return 'whatsapp';
      case 'Skype':
        return 'skype';
      case 'Viber':
        return 'whatsapp';
      case 'Telegram':
        return 'telegram';
      default:
        return 'add';
    }
  }

}
