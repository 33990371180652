import { Component, OnInit } from '@angular/core';
import { Partner } from 'src/app/models/partner.model';
import { ValidatorFn, AbstractControl, ValidationErrors, FormGroup } from '@angular/forms';
import { asyncScheduler } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { PartnerService } from 'src/app/services/partner.service';

@Component({
  selector: 'app-abstract-partner-card',
  templateUrl: './abstract-partner-card.component.html',
  styleUrls: ['./abstract-partner-card.component.scss']
})
export class AbstractPartnerCardComponent implements OnInit {

  public conflictPartner: Partner = null;
  protected id?: string = null;
  public partnerForm: FormGroup;

  private lastTaxNumber = '';

  constructor(protected partnerService: PartnerService) { }

  ngOnInit(): void {
    const throttleConfig = {
      leading: false,
      trailing: true
    }

    this.partnerForm.controls['taxNumber'].valueChanges
    .pipe(throttleTime(400, asyncScheduler, throttleConfig))
    .subscribe(a => {
      if (this.lastTaxNumber != a) {
      this.partnerService.findByTaxNumber(a).subscribe(result => {

        this.conflictPartner = result;
        this.partnerForm.controls['taxNumber'].updateValueAndValidity();
        });
        this.lastTaxNumber = a;
      }
    });
  }

  getConflictPartnerUrl() : string {
    return `/partners/edit/${this.conflictPartner.id}`;
  }

  taxNumberValidator : ValidatorFn = (control: AbstractControl) : ValidationErrors => {


    if (this.conflictPartner != null && (this.id == null || this.conflictPartner.id.toString() != this.id)) {
      return {'taxNumber': true};
    }
    return null;

 }

}
