import { Component, OnInit, ViewChild } from '@angular/core';
import { PricesService } from '../services/prices.service';
import { PriceFormModel } from '../models/price-form.model';
import { MatDialog } from '@angular/material/dialog';
import { NamedObject } from '../models/named-object.model';
import { PricesEditGood } from '../models/prices-edit-goood.model';
import { PriceItemData } from '../models/price-item-data.model';
import { GoodEditDialogComponent } from './modals/good-edit-dialog/good-edit-dialog.component';
import { GoodService } from '../services/good.service';
import { GoodEditModel } from '../models/good-edit.model';
import { Observable } from 'rxjs';
import { PartnerOfferSelectDialogComponent } from './modals/partner-offer-select-dialog/partner-offer-select-dialog.component';
import { PartnerPriceOffer } from '../models/partner-price-offer.model';
import { EditBasePriceManualDialogComponent } from './modals/edit-base-price-manual-dialog/edit-base-price-manual-dialog.component';
import { GlobalSettingsService } from '../services/global-settings.service';
import { PricesSettingsModalComponent } from '../modals/prices-settings-modal/prices-settings-modal.component';
import { CurrencyService } from '../services/currency.service';
import { CdkDragDrop, moveItemInArray, CdkDragEnter, CdkDragStart } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import { DialogService } from '../services/dialog.service';
import { AuthenticationService } from '../services/authentication.service';
import { UserSession } from '../models/user-session';
import { SurchargeEditEvent } from '../shared-components/price-table/price-cell/surcharge-edited-event';

@Component({
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss']
})
export class PricesComponent implements OnInit {
  @ViewChild('pricesTable') table: MatTable<PricesEditGood>;

  public Model: PriceFormModel;
  dataSource: PricesEditGood[]  = [];
  usdCource: number;

  displayedColumns = [];
  priceTypes = new Map<number, NamedObject>();
  goodsMap = new Map<number, PricesEditGood>();
  currentSession: UserSession;

  constructor(private priceService: PricesService, private goodService: GoodService,
    public dialog: MatDialog, private globalSettingsService: GlobalSettingsService,
    private currencyService: CurrencyService, private dialogService: DialogService,
    private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.reloadData();
    this.GetCource().subscribe(result => this.usdCource = result);
    this.authenticationService.currentSession.subscribe(x => this.currentSession = x);
  }

  /**
   * GetCource
   */
  public GetCource() : Observable<number> {
    return this.currencyService.GetCource('USD');
  }

  public addGood(groupId: number) {
    const goodDialogRef = this.dialog.open(GoodEditDialogComponent, {data: {name: '', comment: '', groupId: groupId}});

    goodDialogRef.afterClosed().subscribe(result => {
      if (result!=null) {
        this.doAddGood(result);
      }
    });
  }

  public deleteGood(goodId: number) {
    this.dialogService.yesNoQuestion('Вы уверены, что хотите удалить выбранную позицию? Все связанные с ней данные будут безвозвратно удалены!',
    'Удаление номенклатуры', true)
    .subscribe(result => {
      if (result)
      {
        this.doDeleteGood(goodId);
      }
    });
  }

  selectPriceOffer(goodId: number) {
    const dialogRef = this.dialog.open(PartnerOfferSelectDialogComponent, {data: {goodId: goodId}});

    dialogRef.afterClosed().subscribe(result => {
      if (result != null)
        this.doUpdatePrice(goodId, result);
    });
  }

  inputPriceManual(good: PricesEditGood) {
    const dialogRef = this.dialog.open(EditBasePriceManualDialogComponent, {data: {goodName: good.name, basePrice: good.basePrice}});

    dialogRef.afterClosed().subscribe (result => {
      let validThru = new Date('2020-06-20');
      if (result != null)
        this.doUpdatePriceManual(good.id, result, validThru);
    });

  }

  public editGood(goodId: number) {

    this.goodService.get(goodId).subscribe(good => {
      const goodDialogRef = this.dialog.open(GoodEditDialogComponent, {data: good});
      goodDialogRef.beforeClosed().subscribe(result => {
        if (result != null) {
          this.doUpdateGood(goodId, result);
        }
      });
    });
  }

  /**
   * editBasePrice
   */
  public editBasePrice(goodId: number) {

    let good: PricesEditGood = this.goodsMap[goodId];

    if (!good.inputPriceManual)
    {
      this.selectPriceOffer(goodId);
    }
    else
    {
      this.inputPriceManual(good)
    }
  }

  public editSettings() {

    this.globalSettingsService.getPricesSettings().subscribe(settings => {
      const goodDialogRef = this.dialog.open(PricesSettingsModalComponent, {data: settings});
      goodDialogRef.afterClosed().subscribe(result => {
        this.globalSettingsService.updatePricesSettings(result).subscribe(() => this.reloadData());
      });
    });


  }

  reloadData()
  {
    this.priceService.getPrices().subscribe((model: PriceFormModel) => {
      this.Model = model;
      this.dataSource = model.goods;

      this.displayedColumns = ['name', 'price' ];
      model.priceTypes.forEach(pt => {
        this.displayedColumns.push(`pt_${pt.id}`);
        this.priceTypes[pt.id] = pt;
      });

      model.goods.forEach(good => {
        this.goodsMap[good.id] = good;
      });

    });
  }

  doRefreshGood(newGood: PricesEditGood) {
    let good = this.Model.goods.filter(a=>a.id == newGood.id && !a.isGroup)[0];
    good.basePrice = newGood.basePrice;
    good.currency = newGood.currency;
    good.priceDetails = newGood.priceDetails;
    good.prices = newGood.prices;
  }

  doUpdatePrice(goodId: number, offer: PartnerPriceOffer) {

    this.priceService.updateBasePriceFromOffer(goodId, offer).subscribe(result => {
      this.doRefreshGood(result);
    });
 }

 doUpdatePriceManual(goodId: number, basePrice: number, validThru: Date) {
  this.priceService.updateBasePriceManual(goodId, basePrice, validThru).subscribe(result => {
    this.doRefreshGood(result);
  });
 }

  doAddGood(good: GoodEditModel)
  {
    this.goodService.addGood(good).subscribe(result => {
      this.reloadData();
    })
  }

  doUpdateGood(goodId: number, good: GoodEditModel) {
    this.goodService.update(goodId, good).subscribe(result => {
      this.reloadData();
    });
  }

  doDeleteGood(goodId: number) {
    this.goodService.delete(goodId).subscribe(result => {
      this.reloadData();
    });
  }

  updateSurcharge(event: SurchargeEditEvent) {
    let newPrice = this.priceService.updateSurcharge(event.goodId, event.priceId, event.surcharge).subscribe(newPrice =>
      {
        let good: PricesEditGood = this.goodsMap[event.goodId];
        if (event.priceId in good.prices)
        {
          let price : PriceItemData = good.prices[event.priceId];
          price.surcharge = newPrice.surcharge;
          price.price = newPrice.price;
        }
        else
        {
          good.prices[event.priceId] = newPrice;
        }
      });
  }

  isGroup(index, item): boolean {
    return item.isGroup;
  }

  getCurrencySign(currencyCode: string) : string {
    return this.currencyService.getSign(currencyCode);
  }

  getGroupsCount(index) : number {

    var groupsCount = 0;
    for(let i=0; i<index;++i) {

      var item = this.dataSource[i];

      if (item.isGroup)
        groupsCount ++;
    }

    return groupsCount;
  }

  dropTable(event: CdkDragDrop<PricesEditGood[]>) {

    if (event.item.data.isGroup)
      return;

    let currentItem =  this.dataSource[event.currentIndex];
    let item: PricesEditGood = event.item.data;

    if (currentItem.isGroup)
      return;

    if (this.getGroupsCount(event.currentIndex)!=this.getGroupsCount(event.previousIndex))
      return;

    const moveAfterGood = this.dataSource[event.currentIndex-1];

    this.priceService.updatePriceOrder(item.id, moveAfterGood.isGroup ? null : moveAfterGood.id).subscribe(result => {
      const prevIndex = this.dataSource.findIndex((d) => d === event.item.data);
      moveItemInArray(this.dataSource, event.previousIndex, event.currentIndex);
      this.table.renderRows();
    }, error => {

    });
  }

  openPriceList(priceTypeId: number) {
    window.open(`api/Prices/buildPrice?priceId=${priceTypeId}`, '_blank');
  }
}
