import { ProductPicture } from "./products/product-picture";

export interface ProductEditModel {
  id: number;
  name: string;
  price: number;
  description: string;
  components: ProductComponent[];
  pictures?: ProductPicture[];
}

export interface ProductCreateModel extends ProductEditModel {
  parentGroupId?: number;
}

export interface ProductPriceViewModel {
  id: number;
  name: string;
  price: number;
  code: string;
  description: string;
  components: ProductComponent[];
  pictures?: number[];
}

export enum ComponentType {
  Raw = "raw", Component = "component"
}

export interface ProductComponent  {
  id: number;
  type: ComponentType,
  name: string;
  percentage: number;
}

export interface ProductComponentAddingEvent {
  componentId: number;
  componentType: ComponentType;
  cancel: boolean;
}
