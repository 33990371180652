import { Component, Input, OnInit } from '@angular/core';
import { IPagedData } from 'src/app/models/common/paged-data';

@Component({
  selector: 'app-paged-bar',
  templateUrl: './paged-bar.component.html',
  styleUrls: ['./paged-bar.component.scss']
})
export class PagedBarComponent implements OnInit {

  @Input()
  public pagesData : IPagedData;

  constructor() { }

  ngOnInit() {
    this.pagesData.pageSize = 10;
    this.pagesData.totalCount = 101;
  }

  public getPages() : number[] {
    var pagesCount = 10;//this.pagesData.totalCount / this.pagesData.pageSize
    return Array.from(Array(pagesCount).keys()).map(x => x + 1);
  }

  pageSelected(pageIndex: number) {

  }

}
