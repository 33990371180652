<h1>Новости</h1>
<app-add-news *ngIf="userHasEditRights" (done)="newAdded($event)"></app-add-news>

<div class="feed">
  <app-news-view *ngFor="let n of allNews" [newsData]="n" [userHasEditRights]="userHasEditRights"
  (deleteNews)="deleteNews($event)"
  (publishNews)="publishNews($event)"
  (unPublishNews)="unPublishNews($event)"
  ></app-news-view>
</div>
<div *ngIf="canFetchMore" class="fetchMore">
  <a href="javascript:void(0)" (click)="loadPage()">Показать еще</a>
</div>
