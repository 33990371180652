import { MatDialogRef } from '@angular/material/dialog';

export class EditDialogBase<TComponent, TData> {

  constructor(public dialogRef: MatDialogRef<TComponent>){}

  ok(result: TData) {
    this.dialogRef.close(result);
  }

  onCancel() {
    this.dialogRef.close();
  }

}
