<p>Редактирование предложений цены</p>
<h2>{{partnerName}}</h2>
<mat-slide-toggle [(ngModel)]="usePriceOffersByFactories" (change)="updateUsePriceOffersByFactories()">Цены по заводам</mat-slide-toggle>
<div *ngIf="!usePriceOffersByFactories">
  <app-offers-editor [offers]="offers" [factoryId]="null" [partnerId]="id" [currentSession]="currentSession"></app-offers-editor>
</div>
<div *ngIf="usePriceOffersByFactories">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let fo of factoryOFfers" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{fo.factoryName}}
        </mat-panel-title>
        <mat-panel-description>
          {{fo.factoryAddress}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-offers-editor [offers]="fo.offers" [factoryId]="fo.factoryId" [partnerId]="id" [currentSession]="currentSession"></app-offers-editor>
      <mat-action-row>
        <button mat-raised-button matTooltip="Удалить завод" (click)="deleteFactory(fo)"><mat-icon>delete</mat-icon>Удалить завод</button>
      </mat-action-row>
    </mat-expansion-panel>
    <mat-action-row>
      <button mat-raised-button (click)="addFactory()"><mat-icon>add</mat-icon>Добавить завод</button>
    </mat-action-row>
  </mat-accordion>
</div>

