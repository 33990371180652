import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { UserSession } from 'src/app/models/user-session';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationsMenuComponent } from 'src/app/shared-components/notifications/notifications-menu/notifications-menu.component';
import { NotificationModel } from 'src/app/models/notification.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  currentSession: UserSession;
  @Output() public sidenavToggle = new EventEmitter();

  notificationsCount: number = 0;

  @ViewChild(NotificationsMenuComponent) notificationsMenu: NotificationsMenuComponent

  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService) {
      this.authenticationService.currentSession.subscribe(x => this.currentSession = x);
    }

  ngOnInit() {
    this.updateNotifications();
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  private updateNotifications() {
    this.notificationService.getNotificationsCount()
    .subscribe(result => {
      this.notificationsCount = result;
    });
  }

  /**
   * logout
   */
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  showNotifications(event) {
    this.notificationsMenu.open(event);
  }

  notificationSelected(notification: NotificationModel) {
    this.notificationService.activateNotification(notification).
    subscribe(() => this.updateNotifications());
  }

}
