<div
  class="ProductCardHorizontal">
  <app-picture-view [pictures]="getPictureIds()" [description]="product.name"></app-picture-view>
  <div class="header-block">
    <a class="title  Link Link_type_default" [href]="'products/' + product.id" target="_self">
      {{product.name}}
    </a>
    <div class="meta">
      <div class="vendor-code">
        Код товара:&nbsp;{{product.code}}
      </div>
    </div>
    <hr class="separator">
  </div>
  <div class="description-block">
    <h2>
      Описание:
    </h2>
    <div>
      {{product.description}}
    </div>
    <h2>
      Состав:
    </h2>
    <ul class="properties">
      <li *ngFor="let c of product.components" class="properties_item"><span class="properties_name">
          {{c.name}}:
        </span><span class="properties_value">
          {{c.percentage}}%
        </span>
      </li>
    </ul>
  </div>
  <div class="buy-block">
    <div class="price-block">
      <app-product-price [price]="product.price" size="m"></app-product-price>
    </div>
  </div>
  <div
    class="js--ProductCardInListing__button-buy js--ProductCardInListing__addToCart js--AddToCart ProductCardHorizontal__buy-link-block ProductCardHorizontal__buy-link-block_visible">
  </div>
  <div class="js--ProductCardInListing__button-order-container ProductCardHorizontal__buy-link-block"
    data-basket-url="https://www.citilink.ru/order/"></div>
</div>
