<div *ngIf="good.basePrice>0">
  <div *ngIf="good.prices[priceType.id];" class="surcharge">
    <a (click)="editSurcharge(priceType.id, good.prices[priceType.id]?.surcharge, good.id, good.name)" href="javascript:void(0);">-{{good.prices[priceType.id]?.surcharge}}%</a>
     ({{good.prices[priceType.id]?.price.deltaCash}}р./{{good.prices[priceType.id]?.price.deltaCashless}}р.)
    <div class="price">{{good.prices[priceType.id]?.price.priceCash}}р./{{good.prices[priceType.id]?.price.priceCashless}}р.</div>
  </div>
  <div *ngIf="!good.prices[priceType.id];" class="surchNotSet"><a (click)="editSurcharge(priceType.id, 0, good.id, good.name)" href="javascript:void(0);">Не задано</a>
  </div>
</div>
<div *ngIf="good.basePrice==0">
  <mat-icon class="priceLocked">warning</mat-icon>
  <div class="basePriceIsNotSet">
    Базовая цена не установлена
  </div>
</div>
