import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { GoodSelectDialogComponent } from 'src/app/modals/common.dialogs/good-select-dialog/good-select-dialog.component';
import { PartnerGoodPriceEditModalComponent } from 'src/app/modals/partner-good-price-edit-modal/partner-good-price-edit-modal.component';
import { GoodToSellModel } from 'src/app/models/good-to-sell.model';
import { NamedObject } from 'src/app/models/named-object.model';
import { UpdatePriceOfferResulModel } from 'src/app/models/partner-price-offer.model';
import { UserSession } from 'src/app/models/user-session';
import { CurrencyService } from 'src/app/services/currency.service';
import { DialogService } from 'src/app/services/dialog.service';
import { PartnerService } from 'src/app/services/partner.service';
import { PricesService } from 'src/app/services/prices.service';

@Component({
  selector: 'app-offers-editor',
  templateUrl: './offers-editor.component.html',
  styleUrls: ['./offers-editor.component.scss']
})
export class OffersEditorComponent implements OnInit {

  @Input()
  offers: GoodToSellModel[] = [];

  @Input()
  factoryId: number;

  @Input()
  partnerId: number;

  @ViewChild('offersTable') table : MatTable<GoodToSellModel>;

  @Input()
  currentSession: UserSession;

  constructor(private currencyService : CurrencyService,
    private partnerService: PartnerService,
    private pricesService: PricesService,
    private dialog: MatDialog,
    private dialogService: DialogService) { }

  ngOnInit(): void {

  }

  formatCurrency(code: string) : string {
    return this.currencyService.getSign(code);
  }

  addOffers() {
    const dialogRef = this.dialog.open(GoodSelectDialogComponent, {data: {selected: this.offers}});
    dialogRef.componentInstance.goodSelected().subscribe(good => {

      if (this.offers.some(a => a.id == good.id))
        return;

      this.addOffer(good);
    });
  }

  addOffer(good: NamedObject) {
    let dialogRef = this.dialog.open(PartnerGoodPriceEditModalComponent, {data: {id: good.id
      , name: good.name
      , price: 0
      , validThru: null
      , currency: null}});

      dialogRef.afterClosed().subscribe(result => {
        if (result != null)
        {
          this.partnerService.addOffer(this.partnerId, this.factoryId, result).subscribe(addResult => {

            this.offers.push(result);
            this.table.renderRows();

            this.handlePriceUpdateResult(addResult);
          });
        }
      });
  }

  editOffer(offer: GoodToSellModel) {

    let dialogRef = this.dialog.open(PartnerGoodPriceEditModalComponent, {data: {id: offer.id
      , name: offer.name
      , price: offer.price
      , validThru: offer.validThru
      , currency: offer.currency}});

    dialogRef.afterClosed().subscribe(result => {
      if (result != null)
        this.updatePrice(offer, result);
    });
  }

  deleteOffer(offer: GoodToSellModel) {
    this.dialogService.yesNoQuestion("Удалить предложение?", null, true)
    .subscribe(result => {
      if (result) {
        this.partnerService.deleteOffer(this.partnerId, this.factoryId, offer.id)
        .subscribe(deleteResult => {
          this.offers = this.offers.filter(a=>a.id != offer.id);
        });
      }
    });
  }

  private updatePrice(currentOffer: GoodToSellModel, updatedOffer: GoodToSellModel) {
    this.partnerService.updatePriceOffer(this.partnerId, this.factoryId, updatedOffer).subscribe(result => {

      currentOffer.price = updatedOffer.price;
      currentOffer.validThru = updatedOffer.validThru;
      currentOffer.currency = updatedOffer.currency;
      this.handlePriceUpdateResult(result);
    });
  }

  private handlePriceUpdateResult(result: UpdatePriceOfferResulModel) {
    if (result.isBest)
    {
      this.dialogService.yesNoQuestion('Данное предложение по выбранной позиции является максимальным.\nУстановить его в качестве актуальной цены?',
      'Лучшее предложение')
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.pricesService.updateBasePriceFromOfferById(result.offerId)
          .subscribe(updatePriceResult => {});
        }
      });

    }
  }

}
