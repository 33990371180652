import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PartnersComponent } from './partners/partners.component'
import { PartnerCreateComponent } from './partners/create/create.component'
import { PartnerEditComponent } from './partners/edit/edit.component'
import { PricesComponent } from './prices/prices.component'
import { from } from 'rxjs';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './helpers/auth.guard';
import { RestoreComponent } from './login/restore/restore.component';
import { CnangePasswordComponent } from './login/cnange-password/cnange-password.component';
import { UsersComponent } from './admin/users/users.component';
import { ShipmentsComponent } from './shipments/shipments.component';
import { EditOffersComponent } from './partners/edit-offers/edit-offers.component';
import { PriceListComponent } from './prices/price-list/price-list.component';
import { FactoriesViewComponent } from './factories/factories-view/factories-view.component';
import { HomeComponent } from './home/home.component';
import { NewsItemPageComponent } from './news/news-item-page/news-item-page.component';
import { ProductPricesPageComponent } from './product-prices/product-prices-page/product-prices-page.component';
import { ProductEditComponent } from './product-prices/product-edit/product-edit.component';

const routes: Routes = [
  { path: 'partners', component: PartnersComponent, canActivate: [AuthGuard] },
  { path: 'partners/create', component: PartnerCreateComponent, canActivate: [AuthGuard] },
  { path: 'partners/edit/:id', component: PartnerEditComponent, canActivate: [AuthGuard] },
  { path: 'partners/:id/offers', component: EditOffersComponent, canActivate: [AuthGuard] },
  { path: 'prices', component: PricesComponent, canActivate: [AuthGuard] },
  { path: 'products-prices', component: ProductPricesPageComponent, canActivate: [AuthGuard]},
  { path: 'shipments', component: ShipmentsComponent, canActivate: [AuthGuard] },
  { path: 'factories', component: FactoriesViewComponent, canActivate: [AuthGuard] },
  { path: 'admin/users', component: UsersComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login/restore', component: RestoreComponent },
  { path: 'login/restore/:key', component: CnangePasswordComponent },
  { path: 'prices/price-list', component: PriceListComponent, canActivate: [AuthGuard] },
  { path: 'products/create', component: ProductEditComponent, canActivate: [AuthGuard] },
  { path: 'products/:id', component: ProductEditComponent, canActivate: [AuthGuard] },
  { path: 'news/:id', component: NewsItemPageComponent, canActivate: [AuthGuard] },
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

