<div class="image-block">
  <a href="javascript:void()" (click)="openPicture()">
  <picture class="picture" data-picture-number="0">
    <source
      [srcset]="currentPictureUrl"
      media="(min-width: 600px)">
    <img class="image Image" [alt]="description"
      [src]="current">
  </picture>
  <div
    class="picture picture-hover">
    <div *ngFor="let p of pictures" class="picture-hover_part" (mouseenter)="dotClicked(p)"
      data-src="">
    </div>
  </div>
  </a>
  <div class="dots-container">
    <div class="ProductImagesDots">
      <div *ngFor="let p of pictures" [ngClass]="{'item': true, 'item_active': p == current}" (click)="dotClicked(p)"></div>
    </div>
  </div>
</div>
