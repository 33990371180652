import { Component, OnInit, Inject } from '@angular/core';
import { PartnerOfferSelectDialogDataModel } from 'src/app/models/partner-offer-select-dialog-data.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PricesService } from 'src/app/services/prices.service';
import { PartnerPriceOffer } from 'src/app/models/partner-price-offer.model';
import { GoodPriceOffersByPartner } from 'src/app/models/good-price-offers-data';

@Component({
  templateUrl: './partner-offer-select-dialog.component.html',
  styleUrls: ['./partner-offer-select-dialog.component.scss']
})
export class PartnerOfferSelectDialogComponent implements OnInit {

  public offers: PartnerPriceOffer[] = [];
  public partnerOffers: GoodPriceOffersByPartner[];

  constructor(public dialogRef: MatDialogRef<PartnerOfferSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: PartnerOfferSelectDialogDataModel,
    private priceService: PricesService) { }

  ngOnInit(): void {
    this.priceService.getPriceOffers(this.data.goodId)
    .subscribe(result => {
      this.offers = result.offers;
      this.partnerOffers = result.offersByFactories;
    });
  }

  selectOffer(offer: PartnerPriceOffer) {
    this.dialogRef.close(offer);
  }

  getReference(id: number) : string {
    return `partners/${id}`;
  }
}
