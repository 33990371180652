import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PriceTypeModel } from 'src/app/models/price-type.model';
import { PricesEditGood } from 'src/app/models/prices-edit-goood.model';
import { SurchargeEditModalComponent } from 'src/app/prices/modals/surcharge-edit-modal/surcharge-edit-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SurchargeEditEvent } from './surcharge-edited-event';

@Component({
  selector: 'app-price-cell',
  templateUrl: './price-cell.component.html',
  styleUrls: ['./price-cell.component.scss']
})
export class PriceCellComponent implements OnInit {

  @Input()
  good: PricesEditGood;

  @Input()
  priceType: PriceTypeModel;

  @Output()
  updateSurcharge = new EventEmitter<SurchargeEditEvent>();

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  public editSurcharge(priceId: number, surcharge: number, goodId: number, goodName: string)
  {
    const dialogRef = this.dialog.open(SurchargeEditModalComponent, {
      data: {
        priceId: priceId,
        priceTypeName: this.priceType.name,
        goodName: goodName,
        goodId: goodId,
        surcharge: surcharge
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result !== undefined)
      {
        this.updateSurcharge.emit({
          goodId: goodId,
          priceId: priceId,
          surcharge: result
        });
      }
    });
  }

}
