import { Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { min } from 'rxjs/operators';
import { NotificationModel } from 'src/app/models/notification.model';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-notifications-menu',
  templateUrl: './notifications-menu.component.html',
  styleUrls: ['./notifications-menu.component.scss']
})
export class NotificationsMenuComponent implements OnInit {

  @HostBinding("style.visibility") visibility = "hidden"
  @HostBinding("style.top") y = "0px"
  @HostBinding("style.left") x = "0px"
  @Input() @HostBinding("style.width") width = "300px"

  notifications: NotificationModel[] = [];
  isQueryDone: boolean = false;

  @Output()
  public notificationSelected = new EventEmitter<NotificationModel>();

  constructor(private service: NotificationService) { }

  ngOnInit(): void {
  }

  open(e: MouseEvent) {

    let w = 300;
    let space = 20;

    let delta = Math.max(0, (e.pageX + w) - window.innerWidth);

    this.x = `${e.pageX - delta - space}px`
    this.y = `${e.pageY}px`

    this.visibility = "visible"

    e.stopPropagation();

    this.isQueryDone = false;

    this.service.getNotifications()
    .subscribe(result => {
      this.notifications = result;
      this.isQueryDone = true;
    });
  }

  close() {
    this.visibility = "hidden"
    this.notifications = [];
  }

  @HostListener('document:click')
  public onDocumentClick() {
    if (this.visibility === "visible") {
      this.close()
    }
  }

  activateNotification(notification: NotificationModel) {
    this.notificationSelected.emit(notification);
  }

}
