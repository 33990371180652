import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PartnerPriceOffer } from 'src/app/models/partner-price-offer.model';
import { CurrencyService } from 'src/app/services/currency.service';

@Component({
  selector: 'app-price-offers-view',
  templateUrl: './price-offers-view.component.html',
  styleUrls: ['./price-offers-view.component.scss']
})
export class PriceOffersViewComponent implements OnInit {

  @Input()
  public offers: PartnerPriceOffer[] = [];

  @Input()
  public showPartners : boolean = true;

  @Output()
  selected = new EventEmitter<PartnerPriceOffer>();

  constructor(private currencyService: CurrencyService) { }

  ngOnInit(): void {
  }

  formatCurrency(currency: string) : string {
    return this.currencyService.getSign(currency);
  }

  selectOffer(offer: PartnerPriceOffer) {
    this.selected.emit(offer);
  }

}
