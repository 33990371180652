import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PricesSettings } from 'src/app/models/settings.model';

@Component({
  templateUrl: './prices-settings-modal.component.html',
  styleUrls: ['./prices-settings-modal.component.scss']
})
export class PricesSettingsModalComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<PricesSettingsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PricesSettings) { }

  ngOnInit(): void {
  }


  onClose(): void {
    this.dialogRef.close();
  }

}
