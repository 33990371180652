import { EventEmitter, Injectable } from '@angular/core';
import { EditableValueComponent } from '../shared-components/editable-value/editable-value.component';

@Injectable({
  providedIn: 'root'
})
export class InlineEditorService {

  closeEditors: EventEmitter<EditableValueComponent> = new EventEmitter();

constructor() { }

  public editorOpened(component: EditableValueComponent) {
    this.closeEditors.emit(component);
  }

}
