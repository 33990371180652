<div>
  <h1>Цены</h1>
  <button (click)="editSettings()" mat-button style="position: absolute; right: 0;"><mat-icon>settings</mat-icon></button>
</div>
<div *ngIf="Model">
  <mat-menu #printPriceMenu="matMenu" [overlapTrigger]="false">
    <button *ngFor="let priceType of Model.priceTypes" mat-menu-item (click)="openPriceList(priceType.id)">
      {{priceType.name}}
    </button>
  </mat-menu>
  <button matButton mat-stroked-button [matMenuTriggerFor]="printPriceMenu"><mat-icon>print</mat-icon></button>
  Курс доллара (ЦБР): {{usdCource}}р.
</div>
<div *ngIf="Model" class="price-table-wrapper">
<mat-table #pricesTable [dataSource]="dataSource" cdkDropList
[cdkDropListData]="dataSource"
(cdkDropListDropped)="dropTable($event)">
  <ng-container  matColumnDef="name" [sticky]="true">
    <mat-header-cell *matHeaderCellDef>Наименование металла</mat-header-cell>
    <mat-cell class="nameCell" *matCellDef="let good">
      <a href="javascript:void(0)" (click)="editGood(good.id)">{{good.name}}</a>
      <div *ngIf="good.baseGood"> <mat-icon>arrow_forward</mat-icon>({{good.baseGood}})</div>
      <button *ngIf="currentSession?.isAdmin" class="incellButton" mat-mini-fab (click)="deleteGood(good.id)" >
        <mat-icon>delete</mat-icon>
       </button>
    </mat-cell>
</ng-container>
<ng-container matColumnDef="groupHeader">
  <mat-cell *matCellDef="let group">
    <div>{{group.name}}</div>
    <div>
    <button class="incellButton" mat-mini-fab (click)="addGood(group.id)">
      <mat-icon>add</mat-icon>
    </button>
    </div>
  </mat-cell>
</ng-container>
<ng-container matColumnDef="actions">
  <mat-header-cell class="actionColumn" *matHeaderCellDef >  </mat-header-cell>
  <mat-cell *matCellDef="let good" >
       <button *ngIf="currentSession.isAdmin" mat-mini-fab (click)="deleteGood(good.id)" >
        <mat-icon>delete</mat-icon>
       </button>
  </mat-cell>
</ng-container>
<ng-container  matColumnDef="price">
  <mat-header-cell *matHeaderCellDef>Базовая цена</mat-header-cell>
  <mat-cell *matCellDef="let good">
    <ul>
      <li *ngIf="good.priceDetails?.expirationDate  && good.baseGood==null" class="basePriceDetails">Истекает: {{good.priceDetails?.expirationDate}}</li>
      <li *ngIf="good.basePrice>0 && good.baseGood==null" [ngClass]="{'expiredBasePrice': good.priceDetails?.isExpired , 'actualBasePrice': !(good.priceDetails?.isExpired)||good.priceDetails==null }">{{good.basePrice}} {{getCurrencySign(good.currency)}}.
        <button mat-icon-button (click)="editBasePrice(good.id)"><mat-icon>edit</mat-icon></button>
      </li>
      <li *ngIf="good.basePrice>0 && good.baseGood!=null" class="calculatedBasePrice" >{{good.basePrice}} {{getCurrencySign(good.currency)}}. <mat-icon>lock</mat-icon></li>
      <li *ngIf="good.basePrice==0" class="basePriceDetails">Не задано<button mat-icon-button (click)="editBasePrice(good.id)" ><mat-icon>attach_money</mat-icon></button></li>
      <li [matTooltip]="good.priceDetails?.factory" *ngIf="good.priceDetails?.partner && good.priceDetails?.partnerId!=null && good.baseGood==null" class="basePriceDetails">
        <a href="partners/edit/{{good.priceDetails?.partnerId}}">{{good.priceDetails?.partner}}</a>
      </li>
      <li *ngIf="good.priceDetails?.partnerId==null && good.baseGood==null" class="basePriceDetails">{{good.priceDetails?.partner}}</li>
  </ul>
  </mat-cell>
</ng-container>
<div *ngFor="let item of Model.priceTypes">
  <ng-container  matColumnDef="pt_{{item.id}}">
    <mat-header-cell *matHeaderCellDef>
      <div>{{item.name}} (Нал/Б/н) <a *ngIf="item.description.length > 0" [matTooltip]='item.description'> <mat-icon>info</mat-icon></a> </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let good">
      <app-price-cell [good]="good" [priceType]="item" (updateSurcharge)="updateSurcharge($event)"></app-price-cell>
  </mat-cell>
  </ng-container>
</div>
<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
<mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag [cdkDragData]="row" ></mat-row>
<mat-row class="groupRow" *matRowDef="let row; columns: ['groupHeader']; when: isGroup" cdkDrag [cdkDragData]="row" cdkDragDisabled> </mat-row>
</mat-table>
</div>
