
<h1>Пользователи</h1>
<mat-card>
<mat-toolbar>
  <button mat-raised-button (click)="addUser()"><mat-icon>add</mat-icon></button>
  <span class="filler"></span>
  <span class="search"><mat-form-field>
    <mat-icon matPrefix>search</mat-icon>
    <input type="search" class="search-input" matInput [(ngModel)]="searchString" placeholder="Поиск..." (keyup)="filter()"></mat-form-field> </span>
</mat-toolbar>

<mat-table [dataSource]="filteredUsers">
  <ng-container  matColumnDef="isActive">
    <mat-header-cell *matHeaderCellDef>Активный</mat-header-cell>
    <mat-cell *matCellDef="let user">
      <mat-slide-toggle [checked]="user.isActive" [disabled]="true"> </mat-slide-toggle>
    </mat-cell>
  </ng-container>

  <ng-container  matColumnDef="email">
    <mat-header-cell *matHeaderCellDef>E-Mail</mat-header-cell>
    <mat-cell *matCellDef="let user">
      {{user.eMail}}
    </mat-cell>
  </ng-container>

  <ng-container  matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>Имя пользователя</mat-header-cell>
    <mat-cell *matCellDef="let user">
      {{user.fullName}}
    </mat-cell>
  </ng-container>

  <ng-container  matColumnDef="isAdmin">
    <mat-header-cell *matHeaderCellDef>Администратор</mat-header-cell>
    <mat-cell *matCellDef="let user">
      <mat-slide-toggle [checked]="user.isAdmin" [disabled]="true"> </mat-slide-toggle>
    </mat-cell>
  </ng-container>

  <ng-container  matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let user">
      <button mat-button (click)="editUser(user)"><mat-icon>edit</mat-icon></button>
      <span></span>
      <button mat-button (click)="changePassword(user)"><mat-icon>vpn_key</mat-icon></button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="['isActive','email','name', 'isAdmin', 'actions'];"></mat-header-row>
  <mat-row *matRowDef="let row; columns: ['isActive', 'email', 'name', 'isAdmin', 'actions'];"></mat-row>

</mat-table>
</mat-card>
