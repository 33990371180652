<div *ngIf="!editMode">
  <span>{{value}}</span>
  <button mat-button (click)="editValue()" >
    <mat-icon>edit</mat-icon>
  </button>
</div>
<div *ngIf="editMode" class="editor">
  <mat-form-field>
    <input #editor matInput [(ngModel)]="editorValue" type="number">
  </mat-form-field>
  <button mat-button class="button" (click)="confirmEdit()">
    <mat-icon>done</mat-icon>
  </button>
  <button mat-button (click)="cancelEdit()">
    <mat-icon>clear</mat-icon>
  </button>
</div>
