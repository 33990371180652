import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditDialogBase } from 'src/app/modals/common.dialogs/edit-dialog-base';
import { FactoryModel } from 'src/app/models/factory.model';

@Component({
  templateUrl: './factory-edit-modal.component.html',
  styleUrls: ['./factory-edit-modal.component.scss']
})
export class FactoryEditModalComponent extends EditDialogBase<FactoryEditModalComponent, FactoryModel> implements OnInit {

  constructor(dialogRef: MatDialogRef<FactoryEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FactoryModel) {
      super(dialogRef);
    }

  ngOnInit(): void {
  }

}
