import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { InlineEditorService } from 'src/app/services/inline-editor.service';

@Component({
  selector: 'app-editable-value',
  templateUrl: './editable-value.component.html',
  styleUrls: ['./editable-value.component.scss']
})
export class EditableValueComponent implements OnInit {

  @ViewChild('editor') editor : MatInput

  @Input()
  value: number;

  @Output()
  onChange = new EventEmitter();

  editorValue: number;

  editMode: boolean = false;

  constructor(private editorService: InlineEditorService) { }

  ngOnInit() {
    this.editorService.closeEditors.subscribe(editor => {
      if (editor != this) {
        this.cancelEdit();
      }
    });
  }

  editValue() {
    this.editorValue = this.value;
    this.openEditor();
  }

  confirmEdit() {
    this.value = this.editorValue;
    this.onChange.emit(this.value);
    this.closeEditor();
  }

  cancelEdit() {
    this.closeEditor();
  }

  private closeEditor() {
    this.editMode = false;
  }

  private openEditor() {
    this.editMode = true;
    this.editorService.editorOpened(this);
  }

}
