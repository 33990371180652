<mat-form-field>
  <input matInput placeholder="Заголовок" [(ngModel)]="title">
</mat-form-field>
<div>
  <mat-slide-toggle [(ngModel)]="isImportant">Важная новость</mat-slide-toggle>
</div>
<angular-editor [config]="editorConfig" [(ngModel)]="htmlData" ></angular-editor>

<mat-card-actions>
  <button mat-raised-button (click)="submit()">OK</button>
  <button mat-raised-button (click)="cancel()">Отмена</button>
</mat-card-actions>
