<h2>Выбор завода</h2>
<ul>
  <li *ngFor="let factory of factories">
    <div>
      <div><h1>
        <a *ngIf="!isFactoryDisabled(factory.id)" href="javascript:void(0)" (click)="select(factory)">{{factory.name}}</a>
        <label class="disabledFactory" *ngIf="isFactoryDisabled(factory.id)">{{factory.name}}</label>
      </h1></div>
      <div>{{factory.address}}</div>
    </div>
  </li>
</ul>
