import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';

import { PartnerForCreation } from '../../models/partner.create.model';
import { PartnerService } from '../../services/partner.service';
import { AbstractPartnerCardComponent } from '../abstract-partner-card/abstract-partner-card.component';
import { PartnerContact } from 'src/app/models/partner.model';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class PartnerCreateComponent extends AbstractPartnerCardComponent implements OnInit {

  public partnerForm: FormGroup;
  contacts: PartnerContact[] = [];
  constructor(private location: Location, service: PartnerService) {
    super(service);
   }

  ngOnInit(): void {
    this.partnerForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      address: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      comment: new FormControl('', [Validators.maxLength(1000)]),
      taxNumber: new FormControl('', [Validators.maxLength(12), Validators.pattern('[0-9]*'), this.taxNumberValidator])
    });

    super.ngOnInit();
  }

  public hasError = (controlName: string, errorName: string) =>{
    return this.partnerForm.controls[controlName].hasError(errorName);
  }

  public onCancel = () => {
    this.location.back();
  }

  public createOwner = (ownerFormValue) => {
    if (this.partnerForm.valid) {
      this.executeOwnerCreation(ownerFormValue);
    }
  }

  private executeOwnerCreation = (partnerFormValue) => {
    let partner: PartnerForCreation = {
      name: partnerFormValue.name,
      address: partnerFormValue.address,
      comment: partnerFormValue.comment,
      taxNumber: partnerFormValue.taxNumber,
      contacts: this.contacts
    }
    this.partnerService.create(partner)
      .subscribe(res => {
        //this is temporary, until we create our dialogs
        this.location.back();
      },
      (error => {
        //temporary as well
        this.location.back();
      })
    )
  }

}
