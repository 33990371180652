import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentType, ProductComponentAddingEvent, ProductCreateModel, ProductEditModel } from 'src/app/models/product-edit-model';
import { ProductsService } from 'src/app/services/products.service';
import { Location } from '@angular/common';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit {

  private id?: number;
  private parentId?: number;

  @Input()
  public product: ProductEditModel;

  isNew : boolean = false;

  constructor(private location: Location, private activeRoute: ActivatedRoute, private productsService: ProductsService,
    private dialogService: DialogService) { }

  ngOnInit() {

    this.id = this.activeRoute.snapshot.params['id'];

    this.activeRoute.queryParams
    .subscribe(params => {
      this.parentId = params.parentId;
    });

    if (this.id != null) {
      this.isNew = false;
      this.productsService.get(this.id)
      .subscribe(item => {
        this.product = item;
      });
    } else {
      this.isNew = true;
      this.product = <ProductCreateModel>{
        id: 0,
        name: '',
        description: '',
        price: 0,
        components: [],
        parentGroupId: this.parentId
      };
    }
  }

  save() {
    if (this.isNew) {
      this.productsService.add(this.product)
      .subscribe(savedItem => {
        console.log('added!');
        this.id = savedItem.id;
        this.product = savedItem;
        this.isNew = false;
        this.location.back();
      });
    } else {
      this.productsService.update(this.product)
      .subscribe(r => {
        console.log("saved!");
      });
    }
  }

  cancel() {
    this.dialogService.yesNoQuestion("Это приведет к потере несохраненных данных! Отменить редактирование?")
    .subscribe(result => {
      if (result) {
        this.location.back();
      }
    });

  }

  onComponentEdding(evt: ProductComponentAddingEvent) {
    if (evt.componentType==ComponentType.Component && this.product.id == evt.componentId) {
      evt.cancel = true;
    }
  }

}
