<h1 mat-dialog-title>{{data.priceTypeName}}</h1>
<div mat-dialog-content>
  <p>{{data.goodName}}</p>
  <mat-form-field>
    <input type="number" min="-99" max="99" (keyup)="recalcPrice()" matInput [(ngModel)]="data.surcharge">
  </mat-form-field>
  <div>Цена: {{price.priceCash}}р./{{price.priceCashless}}р.</div>
  <div>Дельта: {{price.deltaCash}}р./{{price.deltaCashless}}р.</div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Отмена</button>
  <button mat-button [mat-dialog-close]="data.surcharge" cdkFocusInitial>OK</button>
</div>
