import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NewsViewModel } from '../models/news-view-model';
import { NewsEditModel } from '../models/news/news-edit-model';
import { AbstractCrudService } from './abstract-crud-service';
import { EnvironmentService } from './enviroment.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService extends AbstractCrudService<NewsEditModel> {

  constructor(http: HttpClient, environmentService: EnvironmentService) {
    super(http, environmentService.getValue('apiUrl', '') +  '/news');
  }

  public getNews(offset: number, count: number) : Observable<NewsViewModel[]> {
    return this.http.get<NewsViewModel[]>(`${this.serviceUrl}?Offset=${offset}&Count=${count}`);
  }

  public get(id: number) : Observable<NewsViewModel> {
    return this.http.get<NewsViewModel>(`${this.serviceUrl}/${id}`);
  }

  public publish(id: number): Observable<NewsViewModel> {
    return this.http.post<NewsViewModel>(`${this.serviceUrl}/${id}/publish`, null);
  }

  public unPublish(id: number): Observable<NewsViewModel> {
    return this.http.post<NewsViewModel>(`${this.serviceUrl}/${id}/unpublish`, null);
  }

  public getNewsIdByNotificationId (notificationId: number) : Observable<number> {
    return this.http.get<number>(`${this.serviceUrl}/byNotification/${notificationId}`);
  }
}
