import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders }   from '@angular/common/http';
import { PriceFormModel } from '../models/price-form.model';
import { Observable } from 'rxjs';
import { PriceItemData } from '../models/price-item-data.model';
import { PartnerPriceOffer } from '../models/partner-price-offer.model';
import { PricesEditGood } from '../models/prices-edit-goood.model';
import { EnvironmentService } from './enviroment.service';
import { PriceModel } from '../models/price.model';
import { GoodPriceOffersData } from '../models/good-price-offers-data';

@Injectable()

export class PricesService {

  private serviceUrl = this.environmentService.getValue('apiUrl', '') + '/Prices';

  constructor(private http: HttpClient, private environmentService: EnvironmentService) { }

  public getPrices(): Observable<PriceFormModel> {
    return this.http.get<PriceFormModel>(this.serviceUrl);
  }

  public updateSurcharge(goodId: number, priceId: number, surcharge: number) : Observable<PriceItemData>
  {
    return this.http.put<PriceItemData>(this.serviceUrl + "/update",
      {
        priceId: priceId,
        goodId: goodId,
        surcharge: surcharge
      });
  }

  public calcPrice(goodId: number, priceTypeId: number, surcharge: number) : Observable<PriceModel>
  {
    return this.http.get<PriceModel>(this.serviceUrl + `/calcPrice?goodId=${goodId}&surcharge=${surcharge}&priceTypeId=${priceTypeId}`);
  }

  public getPriceOffers(goodId: number) : Observable<GoodPriceOffersData> {
    return this.http.get<GoodPriceOffersData>(this.serviceUrl + `/priceOffers/${goodId}`);
  }

  /**
   * updateBasePriceFromOffer
   */
  public updateBasePriceFromOffer(goodId: number, offer: PartnerPriceOffer) : Observable<PricesEditGood> {
    return this.http.put<PricesEditGood>(this.serviceUrl + `/${goodId}/updateBasePriceFromOffer`, offer);
  }

  /**
   *  updateBasePriceFromOfferById
   */
  public updateBasePriceFromOfferById(offerId: number) : Observable<any> {
    return this.http.put(`${this.serviceUrl}/updateBasePriceFromOfferById`, {offerId: offerId});
  }

  public updateBasePriceManual(goodId: number, basePrice: number, validThru: Date) : Observable<PricesEditGood> {
    return this.http.put<PricesEditGood>(this.serviceUrl + '/updateBasePriceManual', {
      goodId: goodId, basePrice: basePrice, validThru: validThru.toISOString()
    });
  }

  /**
   * updatePriceOrder
   */
  public updatePriceOrder(goodId: number, moveAfterGoodId?: number): Observable<any> {
    return this.http.post(`${this.serviceUrl}/updateGoodOrder`, {goodId: goodId, moveAfterGoodId: moveAfterGoodId});
  }
}

