import { EventEmitter } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { NewsEditModel } from 'src/app/models/news/news-edit-model';

@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.scss']
})
export class AddNewsComponent implements OnInit {

  @Output()
  done = new EventEmitter<NewsEditModel>();

  constructor() { }

  isActivated: boolean = false;

  ngOnInit(): void {
  }

  activate() {
    this.isActivated = true;
  }

  cancelEdit() {
    this.isActivated = false;
  }


  saved(news: NewsEditModel) {
    this.isActivated = false;
    this.done.emit(news);
  }

}
