import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss']
})
export class ProductPriceComponent implements OnInit {

  constructor() { }

  @Input()
  public price: number;

  @Input()
  public size: string = 'm';

  ngOnInit() {
  }

  public getClass() : string {
    return `size_${this.size}`;
  }

}
