<div mat-dialog-content>
  <h1 mat-dialog-title>Выбор номенклатуры</h1>
  <mat-tree [dataSource] = "dataSource" [treeControl] = "treeControl">
    <mat-tree-node *matTreeNodeDef = "let node" matTreeNodeToggle matTreeNodePadding>
      <button mat-icon-button disabled></button>
      <a *ngIf="!isCheched(node)"  href="javascript:void(0)" (click)="onGoodNodeClick(node)"> <mat-icon>add_circle_outline</mat-icon> {{node.name}}</a>
      <div *ngIf="isCheched(node)"><mat-icon>lock</mat-icon> {{node.name}}</div>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef = "let node;when: hasChild" matTreeNodePadding>
      <button mat-icon-button matTreeNodeToggle [attr.aria-label] = "'toggle ' + node.id">
          <mat-icon class = "mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'folder_open' : 'folder'}}
          </mat-icon>
      </button>
      {{node.name}}
    </mat-tree-node>
  </mat-tree>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Закрыть</button>
</div>
