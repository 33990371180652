<div class="formWrapper">
<section fxLayout="row wrap" fxLayoutAlign="center center">
  <mat-card fxFlex="500px" fxFlex.xs="100%">
      <mat-card-title>Карточка партнера</mat-card-title>
      <form *ngIf="partner" [formGroup]="partnerForm" autocomplete="off" novalidate (ngSubmit)="updatePartner(partnerForm.value)" fxLayout="column wrap"
            fxLayoutAlign="center center" fxLayoutGap="10px">
            <mat-card-header> <div *ngIf="!partnerForm.valid">
              Форма содержит ошибки!
            </div> </mat-card-header>
        <mat-card-content>
          <ul class="formList">
            <li>
              <mat-form-field>
                <input matInput type="text" placeholder="Наименование/ФИО партнера" formControlName="name" id="name">
                <mat-hint align="end">Not more then 60 characters long.</mat-hint>
                <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
                <mat-error *ngIf="hasError('name', 'maxlength')">You have more than 60 characters</mat-error>
              </mat-form-field>
            </li>
            <li>
              <mat-form-field>
                <input matInput type="text" placeholder="Адрес" formControlName="address">
                <mat-hint align="end">Not more then 100 characters long.</mat-hint>
                <mat-error *ngIf="hasError('address', 'required')">Заполнение адреса обязательно</mat-error>
                <mat-error *ngIf="hasError('address', 'maxlength')">You have more than 100 characters</mat-error>
              </mat-form-field>
            </li>
            <li>
              <mat-form-field>
                <input matInput type="text" placeholder="ИНН" formControlName="taxNumber" (keyup)="taxNumberChanded($event)">
                <mat-hint align="end">Не более 12 символов</mat-hint>
                <mat-error *ngIf="hasError('taxNumber', 'maxlength')">Вы ввели более 12 символов</mat-error>
                <mat-error *ngIf="hasError('taxNumber','taxNumber')">Существует партнер с этим ИНН: <a [href]="getConflictPartnerUrl()">{{conflictPartner.name}}</a></mat-error>
              </mat-form-field>
            </li>
            <li>
              <mat-form-field>
                <textarea matInput type="text" placeholder="Комментарий" formControlName="comment" rows="4"> </textarea>
                <mat-hint align="end">Не более 1000 символов</mat-hint>
                <mat-error *ngIf="hasError('comment', 'maxlength')">You have more than 100 characters</mat-error>
              </mat-form-field>
            </li>
            <li>
              <div class="row">
                <div class="block">
                    <mat-checkbox [(ngModel)]="partner.isBuyer" [ngModelOptions]="{standalone: true}">Покупатель</mat-checkbox>
                  </div>
                  <div class="block">
                    <mat-checkbox [(ngModel)]="partner.isSeller" [ngModelOptions]="{standalone: true}">Продавец</mat-checkbox>
                  </div>
              </div>
            </li>
        </ul>

      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Контакты
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <app-contacts-editor [contactData]="partner.contacts"></app-contacts-editor>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="partner.isBuyer">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Адреса отгрузки
            </mat-panel-title>
            <mat-panel-description>
              Адреса точек покупателя, на которые производится отгрузка
            </mat-panel-description>
          </mat-expansion-panel-header>
          <app-factories-list [factories]="partner.factories"></app-factories-list>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="partner.isBuyer">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Лом на продажу
            </mat-panel-title>
            <mat-panel-description>
              Лом, который мы продаем покупателю
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-form-field>
            <input matInput type="number" placeholder="Транспортные расходы (р/тонну)" formControlName="transportPrice" >
          </mat-form-field>
          <div>
          <a [href]="getOffersUrl()"><mat-icon>monetization_on</mat-icon>Ценовые предложения</a>
          </div>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="true" *ngIf="partner.isSeller">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Закупаемый лом
              </mat-panel-title>
              <mat-panel-description>
                Лом, который клиент сдает нам
              </mat-panel-description>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card-content>
        <mat-card-actions align="center">
          <button mat-raised-button color="primary" [disabled]="!partnerForm.valid">Сохранить</button>
          <button type="button" mat-raised-button color="warn" (click)="onCancel()">Отмена</button>
        </mat-card-actions>

      </form>
    </mat-card>

</section>
</div>
