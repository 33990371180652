<div *ngIf="pictureId == null">
  <img src="assets/loader.gif">
</div>
<div *ngIf="pictureId != null" class="picture-container">
  <img [src]="getImagePath()" class="picture-view">
  <div class="buttons-container">
    <button class="view-button" mat-mini-fab (click)="viewImage(pictureId)"><mat-icon>zoom_in</mat-icon></button>
    <button class="delete-button" mat-mini-fab (click)="deleteImage()"><mat-icon>delete</mat-icon></button>
  </div>
</div>
