import { Component, Input, OnInit } from '@angular/core';
import { IPagedData } from 'src/app/models/common/paged-data';
import { ProductEditModel } from 'src/app/models/product-edit-model';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  @Input()
  public pagedData: IPagedData;

  @Input()
  public products: ProductEditModel[];

  @Input()
  public parentId?: number;

  constructor() { }

  ngOnInit() {
  }

}
