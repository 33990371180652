import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class AbstractCrudService<TModel> {
  constructor(protected http: HttpClient, protected serviceUrl: string ) {}

  public add(item: TModel) : Observable<TModel> {
    return this.http.post<TModel>(`${this.serviceUrl}/add`, { item: item });
  }

  public update(item: TModel) : Observable<any> {
    return this.http.put(`${this.serviceUrl}/update`, { item: item });
  }

  public delete(id: number): Observable<any> {
    return this.http.delete(`${this.serviceUrl}/${id}/delete`);
  }

}
