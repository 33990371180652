import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  public getBeginOfMonth() : Date {
    var date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  public getEndOfMonth() : Date {
    var date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  public today() :  Date{
    var date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

}
