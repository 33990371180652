import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserSession } from '../models/user-session';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CheckRestorePasswordRequestResult } from '../models/check-restore-password-request-result.model';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentSessionKey = 'currentSession';

  private currentSessionSubject: BehaviorSubject<UserSession>;
  public currentSession: Observable<UserSession>;

  constructor(private http: HttpClient) {
    this.currentSessionSubject = new BehaviorSubject<UserSession>(JSON.parse(localStorage.getItem(this.currentSessionKey)));
    this.currentSession = this.currentSessionSubject.asObservable();
   }

  public get currentSessionValue(): UserSession {
    return this.currentSessionSubject.value;
}

  restorePassword(eMail: string) {
    return this.http.post(`${environment.apiUrl}/users/restorePassword/${eMail}`, {eMail: eMail});
  }

  login(eMail: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}/users/authenticate`, { eMail, password })
        .pipe(map(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(this.currentSessionKey, JSON.stringify(user));
            this.currentSessionSubject.next(user);
            return user;
        }));
}

logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(this.currentSessionKey);
    this.currentSessionSubject.next(null);
}

checkRestorePasswordRequest(key: string) : Observable<CheckRestorePasswordRequestResult>  {
  return this.http.get<CheckRestorePasswordRequestResult>(`${environment.apiUrl}/users/checkRestorePasswordRequest?Key=${key}`);
}

changePasswordByRequest(key: string, newPassword: string) : Observable<any> {
  return this.http.post(`${environment.apiUrl}/users/changePasswordByRequest`, {requestKey: key, newPassword: newPassword});
}

getUsers() : Observable<User[]> {
  return this.http.get<User[]>(`${environment.apiUrl}/Users/users`);
}

updateUser(user: User) : Observable<User> {
  return this.http.post<User>(`${environment.apiUrl}/Users/users/update`, user);
}

addUser(user: User) : Observable<User> {
  return this.http.post<User>(`${environment.apiUrl}/Users/add`, user);
}

changePassword(userId: number, newPassword: string) : Observable<any> {
  return this.http.post(`${environment.apiUrl}/Users/changePassword`, {userId: userId, newPassword: newPassword});
}

}
