<div *ngIf="product!=null">
  <mat-card>
    <mat-card-title>
      Изделие - <span *ngIf="!isNew">редактирование</span><span *ngIf="isNew">Создание</span>
    </mat-card-title>
      <mat-card-content>
        <div>
          <mat-form-field>
            <input matInput type="text" placeholder="Наименование" [(ngModel)]="product.name">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <textarea matInput type="text" placeholder="Описание" rows="6" [(ngModel)]="product.description"></textarea>
          </mat-form-field>
        </div>
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Состав
              </mat-panel-title>
              <mat-panel-description>
                Содержание сырья и комплектующих в изделии
              </mat-panel-description>
            </mat-expansion-panel-header>
            <app-product-components-edit [components]="product.components" (onComponentAdding)="onComponentEdding($event)"></app-product-components-edit>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Изображения
              </mat-panel-title>
              <mat-panel-description>
                Примерные изображения изделия
              </mat-panel-description>
            </mat-expansion-panel-header>
            <app-product-pictures-edit [pictures]="product.pictures"></app-product-pictures-edit>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button (click)="save()">Сохранить</button>
      <button mat-raised-button color="warn" (click)="cancel()">Отмена</button>
    </mat-card-actions>

  </mat-card>
</div>
