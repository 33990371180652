import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NewsViewModel } from 'src/app/models/news-view-model';
import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'app-news-item-page',
  templateUrl: './news-item-page.component.html',
  styleUrls: ['./news-item-page.component.scss']
})
export class NewsItemPageComponent implements OnInit {

  newsData : NewsViewModel;
  id;
  constructor(private activeRoute: ActivatedRoute, private service: NewsService, router: Router) {
    router.events.pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.reload();
    });
  }

  private reload() {
    this.id = this.activeRoute.snapshot.params['id'];
    this.service.get(Number.parseInt(this.id))
    .subscribe(result => {
      this.newsData = result;
    });
  }

  ngOnInit(): void {
    this.reload();
  }

}
