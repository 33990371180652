<h1 mat-dialog-title>{{data.title}}</h1>

<p>{{data.message}}</p>

<p><mat-checkbox *ngIf="data.makeSure==true || data.makeSure==null" [(ngModel)] = "iAmSure" >Я уверен в том, что делаю</mat-checkbox> </p>

<div mat-dialog-actions>
  <button mat-button (click)="no()" cdkFocusInitial>Нет</button>
  <button mat-button (click)="yes()" mat-dialog-close [disabled]="(!iAmSure) && (data.makeSure==true || data.makeSure==null)">Да</button>
</div>
