<mat-card>
<mat-card-title>
  Отгрузки
</mat-card-title>

<mat-card-content>
<mat-card-actions>
  <button mat-raised-button (click)="addShipment()"><mat-icon>add</mat-icon></button>
  <span class="filler"></span>
  <div class="period">
  <div class="filterItem">
    <mat-form-field>
      <mat-label>Период с:</mat-label>
      <input matInput [matDatepicker]="pickerDateFrom" [(ngModel)]="dateFrom" (change)="reloadData()">
      <mat-datepicker-toggle matSuffix [for]="pickerDateFrom"></mat-datepicker-toggle>
      <mat-datepicker #pickerDateFrom></mat-datepicker>
      <mat-error>Введите корректную дату</mat-error>
    </mat-form-field>
  </div>
  <div class="filterItem">
    <mat-form-field>
      <mat-label>По:</mat-label>
      <input matInput [matDatepicker]="pickerDateTo" [(ngModel)]="dateTo" (change)="reloadData()">
      <mat-datepicker-toggle matSuffix [for]="pickerDateTo"></mat-datepicker-toggle>
      <mat-datepicker #pickerDateTo (selectedValueChange)="reloadData()"></mat-datepicker>
      <mat-error>Введите корректную дату</mat-error>
    </mat-form-field>
  </div>

    <div class="filterItem">
    <mat-form-field>
      <mat-select placeholder="Партнер" [(ngModel)]="partnerId" (selectionChange)="partnerChanged(partnerId)" (closed)="reloadData()">
        <mat-option *ngFor="let partner of partners" [value]="partner?.id">{{ partner==null ? '\<Не выбрано\>' : partner?.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    </div>

  <div class="filterItem">
    <mat-form-field>
      <mat-select placeholder="Завод" [(ngModel)]="factoryId" (closed)="reloadData()">
        <mat-option *ngFor="let factory of factories" [value]="factory?.id">{{ factory==null ? '\<Не выбрано\>' : factory?.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    </div>
    <div class="filterItem" >
      <button mat-raised-button (click)="reloadData()"><mat-icon>refresh</mat-icon></button>
    </div>
  </div>
</mat-card-actions>

<mat-table [dataSource]="shipments">
  <ng-container  matColumnDef="shipmentDate">
    <mat-header-cell *matHeaderCellDef>Дата</mat-header-cell>
    <mat-cell *matCellDef="let shipment">
      {{shipment.shipmentDate | date:'mediumDate'}}
    </mat-cell>
  </ng-container>

  <ng-container  matColumnDef="partner">
    <mat-header-cell *matHeaderCellDef>Партнер</mat-header-cell>
    <mat-cell *matCellDef="let shipment">
      <div><div>
        {{shipment.partner}}
      </div>
      <div class="factoryName">
        {{shipment.factory}}
      </div>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container  matColumnDef="author">
    <mat-header-cell *matHeaderCellDef>Автор</mat-header-cell>
    <mat-cell *matCellDef="let shipment">
      <div>
        <div>{{shipment.editInfo.creator.fullName}}</div>
        <div class="editDate">{{formatDateTime(shipment.editInfo.created)}}</div>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container  matColumnDef="editor">
    <mat-header-cell *matHeaderCellDef>Отредактировано</mat-header-cell>
    <mat-cell *matCellDef="let shipment">
      <div>
        <div>{{shipment.editInfo.lastEditor.fullName}}</div>
        <div class="editDate">{{formatDateTime(shipment.editInfo.edited)}}</div>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container  matColumnDef="comment">
    <mat-header-cell *matHeaderCellDef>Комментарий</mat-header-cell>
    <mat-cell *matCellDef="let shipment">
      {{shipment.comment}}
    </mat-cell>
  </ng-container>

  <ng-container  matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let shipment">
      <div class="actionButtons">
        <button mat-raised-button color="warn" (click)="deleteShipment(shipment.id)"><mat-icon>delete</mat-icon></button>
        <button mat-raised-button (click)="showPhotos(shipment.id)"><mat-icon>photo</mat-icon></button>
        <button mat-raised-button (click)="editShipment(shipment.id)"><mat-icon>edit</mat-icon></button>
      </div>
    </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="['shipmentDate', 'partner','author', 'editor','comment','actions'];"></mat-header-row>
  <mat-row *matRowDef="let row; columns: ['shipmentDate', 'partner', 'author', 'editor', 'comment', 'actions'];"></mat-row>
</mat-table>
</mat-card-content>
</mat-card>
