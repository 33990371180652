<table>
  <tr *ngFor="let offer of offers">
    <td class="partnerName">{{ showPartners ? offer.partnerName : offer.factoryName}}</td>
    <td class="actualPrice" *ngIf="offer.isActual">
      <mat-icon *ngIf="offer.isBest">grade</mat-icon>
      <a href="javascript:void(0)" (click)="selectOffer(offer)">{{offer.price}}{{formatCurrency(offer.currency)}}</a>
    </td>
    <td class="expiredPrice" *ngIf="!offer.isActual">{{offer.price}}{{formatCurrency(offer.currency)}}</td>
    <td class="actualDate" *ngIf="offer.isActual">До {{offer.validThru}}</td>
    <td class="expiredDate" *ngIf="!offer.isActual">Истекло {{offer.validThru}}</td>
  </tr>
</table>
