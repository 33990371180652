import { Component, OnInit } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
//import 'rxjs/add/observable/of';

import { Partner } from '../models/partner.model'
import { PartnerService } from '../services/partner.service'

@Component({
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  dataSource = new PartnerDataSource(this.partnerService);

  displayedColumns = ['name', 'email', 'phone', 'actions'];

  constructor(private partnerService: PartnerService, private router: Router) { }
  ngOnInit(): void {
  }



  doFilter(filter: string) : void
  {

  }

  public redirectToDetails = (id: string) => {
    let url: string = `/partners/edit/${id}`;
    this.router.navigate([url]);
  }

  /**
   * openOffers
   */
  public openOffers = (id: string) => {
    let url: string = `partners/${id}/offers`;
    this.router.navigate([url]);
  }
}

export class PartnerDataSource extends DataSource<any> {
  constructor(private partnerService: PartnerService) {
    super();
  }
  connect(): Observable<Partner[]> {
    return this.partnerService.getPartners();
  }
  disconnect() {}
}
