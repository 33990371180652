<table>
  <tr *ngFor="let item of contactData"><td><mat-icon [svgIcon]="getContactIcon(item.contactType)"></mat-icon></td>
    <td class="contactType">{{getContactType(item.contactType)}}:</td>
    <td class="contact">{{item.contactData}}</td>
    <td>
      <button mat-mini-fab type="button" (click)="editContact(item)"><mat-icon>edit</mat-icon></button>
      <button mat-mini-fab type="button" (click)="deleteContact(item)"><mat-icon>delete</mat-icon></button>
    </td>
  </tr>
</table>

<div *ngIf="contactData.length==0" class="listIsEmpty">
  Список контактов пуст
</div>

<eco-fab-speed-dial direction="right">
  <eco-fab-speed-dial-trigger >
      <button mat-fab type="button"><mat-icon>add</mat-icon></button>
  </eco-fab-speed-dial-trigger>

  <eco-fab-speed-dial-actions>
      <button *ngFor="let ct of contactTypes" mat-mini-fab type="button" [matTooltip]="ct.displayName" (click)="addContact(ct.key)"><mat-icon [svgIcon]="getContactIcon(ct.key)"></mat-icon></button>
  </eco-fab-speed-dial-actions>
</eco-fab-speed-dial>
