import { Component, OnInit } from '@angular/core';
import { NewsViewModel } from 'src/app/models/news-view-model';
import { NewsEditModel } from 'src/app/models/news/news-edit-model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss']
})
export class NewsFeedComponent implements OnInit {

  allNews: NewsViewModel[] = [];
  private pageSize: number = 10;
  canFetchMore: boolean = true;
  constructor(private service: NewsService, private authService: AuthenticationService) { }

  userHasEditRights: boolean = false;

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage() {
    this.service.getNews(this.allNews.length, this.pageSize)
    .subscribe(result => {
      this.allNews = this.allNews.concat(result);
      this.canFetchMore = result.length > 0;
    });

    this.authService.currentSession.subscribe(session => {
      this.userHasEditRights = session?.isAdmin ?? false;
    });
  }

  newAdded(news: NewsEditModel) {

    this.service.get(news.id)
    .subscribe(result => {
      this.allNews = [result, ... this.allNews];
    });
  }

  deleteNews(news: NewsViewModel) {
    this.service.delete(news.id).subscribe(() => {
      this.allNews = this.allNews.filter(a=>a.id != news.id);
    });
  }

  publishNews(news: NewsViewModel) {
    this.service.publish(news.id)
    .subscribe(result => {
      news.isPublished = result.isPublished;
      news.publishDate = result.publishDate;
    });
  }

  unPublishNews(news: NewsViewModel) {
    this.service.unPublish(news.id)
    .subscribe(result => {
      news.isPublished = result.isPublished;
      news.publishDate = result.publishDate;
    });
  }

}
