import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.scss']
})
export class RestoreComponent implements OnInit {

  restoreForm: FormGroup;

  isDone : boolean = false;
  error: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.restoreForm = this.formBuilder.group({
      eMail: this.formBuilder.control('', [Validators.required, Validators.email])});
  }

  get f() { return this.restoreForm.controls; }

  public hasError = (controlName: string, errorName: string) => {
    return this.restoreForm.controls[controlName].hasError(errorName);
  }

  submit() {
    this.authenticationService.restorePassword(this.restoreForm.get("eMail").value).subscribe( result => {
      this.isDone = true;
    },
    error => {
      error = 'Ошибка при отправке запроса';
    });
  }

  back() {
    this.location.back();
  }

}
