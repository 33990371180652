import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './enviroment.service';
import { Observable } from 'rxjs';
import { PricesSettings } from '../models/settings.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalSettingsService {

  private serviceUrl = this.environmentService.getValue('apiUrl', '') + '/Settings';

  constructor(private http: HttpClient, private environmentService: EnvironmentService) { }

  getPricesSettings() : Observable<PricesSettings> {
    return this.http.get<PricesSettings>(this.serviceUrl + '/prices');
  }

  updatePricesSettings(settings: PricesSettings) : Observable<any> {
    return this.http.put(this.serviceUrl + '/prices', settings);
  }


}
