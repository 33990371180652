import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { GoodToSellModel } from 'src/app/models/good-to-sell.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NamedObject } from 'src/app/models/named-object.model';
import { DictionariesService } from 'src/app/services/dictionaries.service';
import { MatInput } from '@angular/material/input';

@Component({
  templateUrl: './partner-good-price-edit-modal.component.html',
  styleUrls: ['./partner-good-price-edit-modal.component.scss']
})
export class PartnerGoodPriceEditModalComponent implements OnInit {
  @ViewChild('validThru', {static: true}) validThru: MatInput;

  public currencies: NamedObject[] = [];

  constructor(private dialogRef: MatDialogRef<PartnerGoodPriceEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GoodToSellModel, private dicrionariesService: DictionariesService) { }

  ngOnInit(): void {
    this.dicrionariesService.getCurrencies().subscribe(result => {
      this.currencies = result;
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  onOk() {

  }

  isValid() : boolean {
    return this.data.validThru != null && this.data.price > 0 && this.data.currency != null;
  }

  public currencyComparisonFunction = function( option, value ) : boolean {
    return option.id === value.id;
  }

}
