import { Component, OnInit, ViewChild } from '@angular/core';
import { PartnerService } from 'src/app/services/partner.service';
import { GoodToSellModel } from 'src/app/models/good-to-sell.model';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserSession } from 'src/app/models/user-session';
import { MatTable } from '@angular/material/table';
import { FactoryOFfersData } from 'src/app/models/partners/partner-offers-edit-data.model';
import { DialogService } from 'src/app/services/dialog.service';


@Component({
  templateUrl: './edit-offers.component.html',
  styleUrls: ['./edit-offers.component.scss']
})
export class EditOffersComponent implements OnInit {
  @ViewChild('offersTable') table : MatTable<GoodToSellModel>;

  constructor(private activeRoute: ActivatedRoute, private partnerService: PartnerService,
    private authenticationService: AuthenticationService, private dialogService: DialogService) { }

  id: string;
  offers: GoodToSellModel[] = [];
  factoryOFfers: FactoryOFfersData[];
  partnerName: string = '';
  currentSession: UserSession;
  usePriceOffersByFactories: boolean;
  usePriceOffersByFactories_old: boolean;

  ngOnInit(): void {
    this.id = this.activeRoute.snapshot.params['id'];
    this.partnerService.getOffers(this.id).subscribe(result => {
      this.offers = result.offers;
      this.factoryOFfers = result.factoryOffers;
      this.partnerName = result.partnerName;
      this.usePriceOffersByFactories_old = this.usePriceOffersByFactories = result.usePriceOffersByFactories;
    });
    this.authenticationService.currentSession.subscribe(x => this.currentSession = x);
  }

  addFactory() {
    this.dialogService.selectFactory(this.factoryOFfers.map(a=>a.factoryId))
    .subscribe(result => {
      if (result != null) {
        this.partnerService.addFactory(this.id, result.id).subscribe(addResult => {
          this.factoryOFfers.push({offers: [],
            factoryId: result.id,
            factoryName: result.name,
            factoryAddress: result.address
          });
          this.table.renderRows();
        });
      }
    });
  }

  deleteFactory(factory: FactoryOFfersData) {

    this.dialogService.yesNoQuestion('Удалить завод и его цены?', 'Удаление завода', true)
    .subscribe(dlgResult => {

      if (dlgResult) {
        this.partnerService.deleteFactory(this.id, factory.factoryId)
        .subscribe(deleteResult => {
          this.factoryOFfers = this.factoryOFfers.filter(a=>a.factoryId != factory.factoryId);
        });
      }
    });

  }

  updateUsePriceOffersByFactories() {

    this.partnerService.updateUsePriceOffersByFactories(this.id, this.usePriceOffersByFactories)
    .subscribe(() => {
      this.usePriceOffersByFactories_old = this.usePriceOffersByFactories;
    }, error => {
      this.usePriceOffersByFactories = this.usePriceOffersByFactories_old;
    });
  }

}
