import { Component, OnInit, Inject } from '@angular/core';
import { ExpressionBinding } from '@angular/compiler';
import { PartnerContact } from 'src/app/models/partner.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './contact-input-dialog.component.html',
  styleUrls: ['./contact-input-dialog.component.scss']
})
export class ContactInputDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ContactInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditContactData) { }

  ngOnInit(): void {
  }

  getContactIcon() : string {
    switch (this.data.contactType) {
      case 'CellPhone':
        return 'cellphone';
      case 'WorkPhone':
        return 'phone';
      case 'Email':
        return 'at';
      case 'WhatsApp':
        return 'whatsapp';
      case 'Skype':
        return 'skype';
      case 'Viber':
        return 'whatsapp';
      case 'Telegram':
        return 'telegram';
      default:
        return 'add';
    }
  }

  ok() {
    this.dialogRef.close(this.data.contactData);
  }

  cancel() {
    this.dialogRef.close();
  }

}

export interface EditContactData {
  contactType: string;
  contactTypeDisplayName: string;
  contactData: string;
}
