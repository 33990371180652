import { Injectable } from '@angular/core';
import { NamedObject } from '../models/named-object.model';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './enviroment.service';
import { Observable } from 'rxjs';
import { ContactType } from '../models/partners/contact-type.model';

@Injectable({
  providedIn: 'root'
})
export class DictionariesService {

  private serviceUrl = this.environmentService.getValue('apiUrl', '') + '/Dictionaries';

  constructor(private http: HttpClient, private environmentService: EnvironmentService) { }

  getCurrencies() : Observable<NamedObject[]> {
    return this.http.get<NamedObject[]>(this.serviceUrl + '/currencies');
  }

  getBuyers() : Observable<NamedObject[]> {
    return this.http.get<NamedObject[]>(this.serviceUrl + '/buyers');
  }

  getContactTypes() : Observable<ContactType[]> {
    return this.http.get<ContactType[]>(`${this.serviceUrl}/contactTypes`);
  }
}
