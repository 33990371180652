import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NewsEditModel } from 'src/app/models/news/news-edit-model';
import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'app-news-editor',
  templateUrl: './news-editor.component.html',
  styleUrls: ['./news-editor.component.scss']
})
export class NewsEditorComponent implements OnInit {

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
};

  @Input()
  htmlData: string = '';

  @Input()
  title: string = '';

  @Input()
  isImportant: boolean = false;

  constructor(private service: NewsService) { }

  @Input()
  newsId?: number;

  @Output()
  cancelled = new EventEmitter<any>();

  @Output()
  saved = new EventEmitter<NewsEditModel>();

  ngOnInit(): void {
  }

  cancel () {
    this.cancelled.emit();
  }

  submit() {

    let news: NewsEditModel = {
      id: this.newsId,
      text: this.htmlData,
      title: this.title,
      isImportant: this.isImportant
    };

    if (this.newsId == null)
    {
      this.service.add(news)
      .subscribe(result => {
        this.saved.emit(result);
      });
    }
    else {

      this.service.update(news)
      .subscribe(() => {
        this.saved.emit(news);
      })
    }
  }
}
