import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationModel } from '../models/notification.model';
import { EnvironmentService } from './enviroment.service';
import { NewsService } from './news.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  serviceUrl: string;

  constructor(private http : HttpClient, environmentService: EnvironmentService,
    private newsService: NewsService, private router: Router) {
    this.serviceUrl = environmentService.getValue('apiUrl', '') +  '/notifications';
   }

  public getNotificationsCount() : Observable<number> {
    return this.http.get<number>(`${this.serviceUrl}/count`);
  }

  public getNotifications() : Observable<NotificationModel[]> {
    return this.http.get<NotificationModel[]>(this.serviceUrl);
  }

  public markNotificaionAsRead(notificationId: number) : Observable<any> {
    return this.http.put(`${this.serviceUrl}/${notificationId}/markAsRead`, null);
  }


  public activateNotification(notification: NotificationModel) : Observable<any> {

    return new Observable(observer => {
      if (notification.notificationType == "news") {
        this.newsService.getNewsIdByNotificationId(notification.id)
        .subscribe(newsId => {
          this.router.navigate([`news/${newsId}`]);
          this.markNotificaionAsRead(notification.id)
          .subscribe(() => {
            observer.next();
          });
        });
      }
    });
  }

}
