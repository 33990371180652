import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-picture-view',
  templateUrl: './picture-view.component.html',
  styleUrls: ['./picture-view.component.scss']
})
export class PictureViewComponent implements OnInit {

  @Input()
  public pictures: string[];

  @Input()
  public description: string;

  public current: string;
  public currentPictureUrl: string;

  constructor(private dialogService: DialogService) { }

  ngOnInit() {
    if (this.pictures.length > 0) {
      this.setCurrentPicture(this.pictures[0]);
    } else {
      this.currentPictureUrl = "assets/no_photo.jpg"
    }
  }

  public dotClicked(item: string) {
    this.setCurrentPicture(item);
    console.log(item);
  }

  public openPicture() {
    if (this.current != null) {
      this.dialogService.showGalery([`api/Pictures/${this.current}`]);
    }
  }

  private setCurrentPicture(pictureId: string) {
    this.current = pictureId;
    this.currentPictureUrl = `api/Pictures/${pictureId}/thumbnail?size=200`;
  }


}
