import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { Location } from '@angular/common';

import { ParnerForEdit } from '../../models/partner.model'
import { PartnerService } from '../../services/partner.service'
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from 'src/app/services/dialog.service';
import { PartnerShipmentAddressModel } from 'src/app/models/partners/partner-shipment-address.model';
import { PartnerAddressModalComponent } from 'src/app/modals/partner-address-modal/partner-address-modal.component';
import { AbstractPartnerCardComponent } from '../abstract-partner-card/abstract-partner-card.component';

@Component({
  selector: 'app-partner-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class PartnerEditComponent extends AbstractPartnerCardComponent implements OnInit {
  public partner: ParnerForEdit;

  private taxNumberControl : FormControl;

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  addresses: PartnerShipmentAddressModel[] = [];

  constructor(private activeRoute: ActivatedRoute, partnerService: PartnerService,
    private location: Location,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private dialogService: DialogService ) {

    super(partnerService);

    this.partnerForm = fb.group({
      name: fb.control('', [Validators.required, Validators.maxLength(60)]),
      address: fb.control('', [Validators.required, Validators.maxLength(100)]),
      comment: fb.control('',[Validators.maxLength(1000)]),
      taxNumber: fb.control('', [Validators.maxLength(12), this.taxNumberValidator]),
      transportPrice: fb.control(''),
      cellPhone: fb.control('', [Validators.maxLength(13)]),
      officePhone: fb.control('', [Validators.maxLength(13)]),
      viber: fb.control('', [Validators.maxLength(13)]),
      whatsApp: fb.control('', [Validators.maxLength(13)]),
      email: fb.control('', [Validators.maxLength(100), Validators.pattern(this.emailPattern)]),
      skype: fb.control('', [Validators.maxLength(100)])
    });

    this.taxNumberControl = this.partnerForm.controls['taxNumber'] as FormControl;

   }

   formatDate(dateString: string) : string {
     let date = new Date(dateString);
     return date.toLocaleDateString();
   }


  ngOnInit(): void {
    super.ngOnInit();
    this.getPartnerDetails();

  }

  public hasError = (controlName: string, errorName: string) => {
    return this.partnerForm.controls[controlName].hasError(errorName);
  }

  public onCancel = () => {
    this.location.back();
  }

  /**
   * updatePartner
partner: Partner   */
  public updatePartner(partner: ParnerForEdit) {
    if (this.partnerForm.valid) {
      this.executePartnerUpdate(partner);
    }
  }

  private executePartnerUpdate = (partnerFormValue) => {

    this.partner.name = partnerFormValue.name;
    this.partner.address = partnerFormValue.address;
    this.partner.taxNumber = partnerFormValue.taxNumber;
    this.partner.comment = partnerFormValue.comment;
    this.partner.transportPrice = partnerFormValue.transportPrice;
    this.partner.cellPhone = partnerFormValue.cellPhone;
    this.partner.officePhone = partnerFormValue.officePhone;
    this.partner.viber = partnerFormValue.viber;
    this.partner.whatsApp = partnerFormValue.whatsApp;
    this.partner.email = partnerFormValue.email;
    this.partner.skype = partnerFormValue.skype;
    this.partner.shipmentAddresses = this.addresses;

    this.partnerService.update(this.id, this.partner)
      .subscribe(res => {
        this.snackBar.open('Сохранено!', null,  {duration: 3000});
        //this is temporary, until we create our dialogs
        this.location.back();
      },
      (error => {
        this.snackBar.open('При сохранении данных возникла ошибка!', null,  {duration: 5000});
        //temporary as well
        //this.location.back();
      })
    )
  }

  private fillPartnerForm()
  {
    this.partnerForm.setValue({
      name: this.partner.name,
      taxNumber: this.partner.taxNumber,
      comment: this.partner.comment,
      transportPrice: this.partner.transportPrice,
      address: this.partner.address,
      cellPhone: this.partner.cellPhone,
      officePhone: this.partner.officePhone,
      viber: this.partner.viber,
      whatsApp: this.partner.whatsApp,
      email: this.partner.email,
      skype: this.partner.skype
    });
    //this.partnerForm.value.address = this.partner.address;
    //this.partnerForm.value.comment = this.partner.comment;
  }

  private getPartnerDetails = () =>{
    this.id = this.activeRoute.snapshot.params['id'];

    this.partnerService.get(this.id)
    .subscribe(res => {
      this.partner = res as ParnerForEdit;
      this.addresses = this.partner.shipmentAddresses;
      this.fillPartnerForm();
    },
    (error) =>{
      //this.errorHandler.handleError(error);
    })
  }

  getOffersUrl(): string {
    return `partners/${this.id}/offers`;
  }

  /**
   * addAddress
   */
  public addAddress() {

    var newItem = {id: null, address: ''};
    const dialogRef = this.dialog.open(PartnerAddressModalComponent, {data: newItem});

    dialogRef.afterClosed().subscribe(result => {
      if (result!=null) {
        this.addresses.push(result);
      }
    });

  }

  /**
   * deleteAddress
   */
  public deleteAddress(address: PartnerShipmentAddressModel) {
    this.dialogService.yesNoQuestion('Удалить адрес отгрузки?', 'Внимание!', true).subscribe(result=> {
      if (result) {
        this.addresses = this.addresses.filter(a=>a != address);
      }
    })
  }

  /**
   * editAddress
   */
  public editAddress(address: PartnerShipmentAddressModel) {

    const dialogRef = this.dialog.open(PartnerAddressModalComponent, {data: {id: address.id, address: address.address}});

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        address.address = result.address;
      }
    });
  }

  /**
   * taxNumberChanded
   */
  public taxNumberChanded(event: any) {


  }

}
