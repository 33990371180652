import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders }   from '@angular/common/http';
import { Observable }   from 'rxjs';
import { Partner, ParnerForEdit } from "../models/partner.model";
import { PartnerForCreation } from '../models/partner.create.model';
import { EnvironmentService } from './enviroment.service';
import { GoodToSellModel } from '../models/good-to-sell.model';
import { UpdatePriceOfferResulModel } from '../models/partner-price-offer.model';
import { PartnerOffersEditData } from '../models/partners/partner-offers-edit-data.model';
import { PartnerAddressModalComponent } from '../modals/partner-address-modal/partner-address-modal.component';
import { PartnerShipmentAddressModel } from '../models/partners/partner-shipment-address.model';
import { PartnerFactoryModel } from '../models/partners/partner-factory.model';

@Injectable()
export class PartnerService {

  private serviceUrl = this.environmentService.getValue('apiUrl', '') +  '/partners';

  constructor(private http: HttpClient, private environmentService: EnvironmentService) { }

  public getPartners(): Observable<Partner[]> {
    return this.http.get<Partner[]>(this.serviceUrl);
  }

  public create(partner: PartnerForCreation)
  {
    return this.http.post(this.serviceUrl + '/create', partner, this.generateHeaders());
  }

  public update(id: string, partner: ParnerForEdit)
  {
    return this.http.put(this.serviceUrl + `/update/${id}`, partner, this.generateHeaders());
  }

  /**
   * getOffers
   */
  public getOffers(id: string) : Observable<PartnerOffersEditData> {
    return this.http.get<PartnerOffersEditData>(`${this.serviceUrl}/${id}/offers`);
  }

  public addOffer(id, factoryId: number, offer: GoodToSellModel) : Observable<UpdatePriceOfferResulModel> {
    return this.http.post<UpdatePriceOfferResulModel>(`${this.serviceUrl}/${id}/addOffer`, {
      goodId: offer.id,
      factoryId: factoryId,
      currency: offer.currency.code,
      price: offer.price,
      validThru: offer.validThru
    });
  }

  /**
   * updatePriceOffer
   */
  public updatePriceOffer(id, factoryId: number, offer: GoodToSellModel): Observable<UpdatePriceOfferResulModel> {
    return this.http.put<UpdatePriceOfferResulModel>(`${this.serviceUrl}/${id}/updateOffer`, {
      goodId: offer.id,
      factoryId: factoryId,
      currency: offer.currency.code,
      price: offer.price,
      validThru: offer.validThru
    });
  }

  /**
   * deleteOffer
   */
  public deleteOffer(partnerId, factoryId: number, goodId: number) : Observable<any> {
    return this.http.delete(`${this.serviceUrl}/${partnerId}/offers/${goodId}/${factoryId}/delete`);
  }

  /**
   * get
id: number   */
  public get(id: string) {
    return this.http.get(this.serviceUrl + `/${id}`);
  }

  public getFactories(id: number) : Observable<PartnerFactoryModel[]> {
    return this.http.get<PartnerFactoryModel[]>(`${this.serviceUrl}/${id}/factories`);
  }

  /**
   * findByTaxNumber
   */
  public findByTaxNumber(taxNumber: string) : Observable<Partner> {
    return this.http.get<Partner>(`${this.serviceUrl}/findByTaxNumber?taxNumber=${taxNumber}`);
  }

  public updateUsePriceOffersByFactories(partnerId: string, value: boolean) : Observable<any> {
    return this.http.put(`${this.serviceUrl}/${partnerId}/setUsePriceOffersByFactories?value=${value}`, null);
  }

  public addFactory(partnerId, factoryId) : Observable<PartnerFactoryModel> {
    return this.http.post<PartnerFactoryModel>(`${this.serviceUrl}/${partnerId}/addFactory?factoryId=${factoryId}`, null);
  }

  public deleteFactory(partnerId, factoryId) : Observable<any> {
    return this.http.delete(`${this.serviceUrl}/${partnerId}/factories/${factoryId}`);
  }

  private generateHeaders() {
    return {
      headers: new HttpHeaders({'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'http://localhost:5000',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
    'Access-Control-Allow-Credentials': 'true'
    })
    }
  }
}
