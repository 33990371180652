import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './shipment-photos-modal.component.html',
  styleUrls: ['./shipment-photos-modal.component.scss']
})
export class ShipmentPhotosModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
