import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from '../modals/common.dialogs/confirm.dialog/confirm.dialog.component';
import { map } from 'rxjs/operators';
import { ContactInputDialogComponent } from '../modals/contact-input-dialog/contact-input-dialog.component';
import { PartnerContact } from '../models/partner.model';
import { FactoryModel } from '../models/factory.model';
import { PartnerFactoriesModalComponent } from '../modals/partner-factories-modal/partner-factories-modal.component';
import { PictureViewModalComponent } from '../modals/picture-view-modal/picture-view-modal.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) { }

  /**
   * yesNoQuestion
   */
  public yesNoQuestion(message: string, title: string = '', makeSure = false) : Observable<boolean> {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {data: {title: title, message: message, makeSure: makeSure}});
    return dialogRef.afterClosed().pipe(map(value => value == 'Y'));
  }

  public inputContact(contactData: string, contactType: string, contactTypeDisplayName: string) : Observable<string> {
    let dialogRef = this.dialog.open(ContactInputDialogComponent, {data: {contactData: contactData, contactType: contactType, contactTypeDisplayName: contactTypeDisplayName}});
    return dialogRef.afterClosed();
  }

  public selectFactory(disableList: number[]) : Observable<FactoryModel> {
    let dialogRef = this.dialog.open(PartnerFactoriesModalComponent, {data: {disableList: disableList}});
    return dialogRef.afterClosed();
  }

  public showGalery(pictures: string[])  {
    let dialogRef = this.dialog.open(PictureViewModalComponent, {data: {pictures: pictures}});
    dialogRef.afterClosed().subscribe(r => {});
  }
}
