import { Injectable } from '@angular/core';
import { GoodEditModel } from '../models/good-edit.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GoodsHierarchyNodeModel } from '../models/goods-hierarchy/goods-hiererchy-node.model';
import { NamedObject } from '../models/named-object.model';
import { EnvironmentService } from './enviroment.service';

@Injectable({
  providedIn: 'root'
})
export class GoodService {

  private serviceUrl = this.environmentService.getValue('apiUrl', '') + '/Goods';

  constructor(private http: HttpClient, private environmentService: EnvironmentService) { }

  public addGood(good: GoodEditModel) : Observable<any> {
    return this.http.post(this.serviceUrl + '/add', good);
  }

  /**
   * get
goodId  : Observable<GoodEditModel>  */
  public get(goodId) : Observable<GoodEditModel> {
    return this.http.get<GoodEditModel>(this.serviceUrl + `/${goodId}`);
  }

  /**
   * update
goodId: number, good: GoodEditModel  : Observable<any>  */
  public update(goodId: number, good: GoodEditModel) : Observable<any> {
    return this.http.put(this.serviceUrl + `/${goodId}/update`, good);
  }

  /**
   * delete
   */
  public delete(goodId: number) : Observable<any> {
    return this.http.delete(this.serviceUrl + `/${goodId}/delete`);
  }

  /**
   * getHiererchy
   */
  public getHiererchy() : Observable<GoodsHierarchyNodeModel[]> {
    return this.http.get<GoodsHierarchyNodeModel[]>(this.serviceUrl + '/hiererchy');
  }

  public getGoodsByGroup(groupId: number) : Observable<NamedObject[]> {
    return this.http.get<NamedObject[]>(this.serviceUrl + `/byGroup/${groupId}`);
  }

}
