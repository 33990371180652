<h2>Партнеры</h2>
<div fxLayout fxLayout.lt-md="column wrap" fxLayoutAlign="center center" fxLayoutGap.gt-sm="250px" fxLayoutGap.lt.md="20px">
  <mat-form-field>
    <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Поиск">
  </mat-form-field>
  <div>
    <a [routerLink]="['/partners/create']" mat-button color="primary">
      <mat-icon>add</mat-icon>
      Новый</a>
  </div>

<mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef> Наименование </mat-header-cell>
    <mat-cell *matCellDef="let partner"> {{partner.name}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef> E-Mail </mat-header-cell>
    <mat-cell *matCellDef="let partner"> {{partner.eMail}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="phone">
    <mat-header-cell *matHeaderCellDef> Телефон </mat-header-cell>
    <mat-cell *matCellDef="let partner"> {{partner.phone}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="company">
    <mat-header-cell *matHeaderCellDef> Company </mat-header-cell>
    <mat-cell *matCellDef="let partner"> {{partner.company.name}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="actions">
    <mat-header-cell  *matHeaderCellDef >  </mat-header-cell>
    <mat-cell *matCellDef="let partner" >
         <button mat-button (click)="redirectToDetails(partner.id)" >
          <mat-icon>edit</mat-icon>
         </button>
        <button mat-mini-fab [matMenuTriggerFor]="menu">
          <mat-icon>
            menu
          </mat-icon>
        </button>
        <mat-menu #menu="matMenu" [overlapTrigger]="false">
          <button *ngIf="partner.isSeller" mat-menu-item >
            <mat-icon>send</mat-icon>
            Отправить прайс
          </button>
          <button *ngIf="partner.isBuyer"  mat-menu-item (click)="openOffers(partner.id)" >
            <mat-icon>monetization_on</mat-icon>
            Ценовые предложения
          </button>
        </mat-menu>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

</div>
