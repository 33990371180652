<div>
  <span *ngFor="let bc of breadCrumps">
    <a (click)="updateContent(bc.id)" href="javascript:void(0)">{{bc.name}}</a>/
  </span>
  <button mat-flat-button (click)="addGroup()"><mat-icon>add</mat-icon></button>
</div>

<div class="content">
  <ul *ngFor="let g of groups">
    <li class="item-list">
      <div class="category-icon-wrapper">
        <div class="category-icon category-icon_phones  ">

        </div>
      </div>
      <div class="item-title-wrapper">
        <div class="item-title">
          <div class="group-item">
            <h4 class="Heading Heading_level_4 ">
              <a class="link_level-1 Link Link_type_default" (click)="updateContent(g.id)" >
                <span class="category-title">
            {{g.name}}
                </span>
              </a>
            </h4>
            <div class="item-actions">
              <button mat-flat-button><mat-icon>remove</mat-icon></button>
            </div>
          </div>
        </div>

        <ul class="children-list">
          <li *ngFor="let sg of g.childs" class="children-item">
            <div class="group-item">
              <a class="item-link  Link Link_type_default" (click)="updateContent(sg.id)" target="_self">{{sg.name}}</a>
              <div class="item-actions">
                <a class="item-link  Link Link_type_default"><mat-icon>edit</mat-icon></a>
                <a class="item-link  Link Link_type_default" (click)="deleteGroup(sg)"><mat-icon>delete</mat-icon></a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
