import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GroupBreadcrumb } from 'src/app/models/products/group-hierarchy-result';
import { ProductGroup } from 'src/app/models/products/product-group';
import { DialogService } from 'src/app/services/dialog.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-product-groups',
  templateUrl: './product-groups.component.html',
  styleUrls: ['./product-groups.component.scss']
})
export class ProductGroupsComponent implements OnInit {

  @Output()
  groupChanged : EventEmitter<number> = new EventEmitter();

  public groups: ProductGroup[] = [];
  public breadCrumps: GroupBreadcrumb[] = [];

  private parentId?: number;

  constructor(private productsService: ProductsService, private dialogService: DialogService) { }

  ngOnInit() {
    this.updateContent(undefined);
  }

  updateContent(parentId?: number) {
    this.productsService.getGroups(parentId)
    .subscribe(result => {
      this.parentId = parentId;
      this.groups = result.groups;
      this.breadCrumps = result.breadCrumbs;
      this.groupChanged.emit(parentId);
    });
  }

  public addGroup() {
    this.productsService.addGroup('Test', this.parentId)
    .subscribe(result => {this.groups.push(result)});
  }

  public deleteGroup(group: ProductGroup) {
    this.dialogService.yesNoQuestion(`Удалить группу "${group.name}"`)
    .subscribe(r => {

    });

  }
}
