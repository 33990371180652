import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PartnerShipmentAddressModel } from 'src/app/models/partners/partner-shipment-address.model';

@Component({
  templateUrl: './partner-address-modal.component.html',
  styleUrls: ['./partner-address-modal.component.scss']
})
export class PartnerAddressModalComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<PartnerAddressModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PartnerShipmentAddressModel) { }




  onClose(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

  isValid(): boolean {
    return this.data.address.length > 0;
  }

}
