<mat-card [ngClass]="{'draftNews': !newsData.isPublished && !isEditMode, 'publishedNews': newsData.isPublished && isEditMode }">
  <div *ngIf="!isEditMode">
  <mat-card-header>
    <h1 [ngClass]="{'important': newsData.isImportant}">
      <mat-icon *ngIf="newsData.isImportant">warning</mat-icon>
      {{newsData.title}}
      <div *ngIf="!newsData.isPublished" class="draft">(черновик)</div>
    </h1>
  </mat-card-header>
  <div style="display: inline-block;">
  <div style="display: inline-block;">
    <div class="heading">Автор:</div><div class="author">{{newsData.author}}</div>
  </div>
  <div class="newsDateBlock" *ngIf="newsData.isPublished">
    <div class="heading">Дата:</div>
    <div class="newsDate">{{newsData.publishDate | date:'dd MMMM yyyy HH:mm'}}</div>
  </div>
  </div>
  <hr>
  <mat-card-content>
    <div [innerHTML]="newsData.text">
    </div>
  </mat-card-content>
  <mat-card-actions *ngIf="userHasEditRights">
      <button *ngIf="!newsData.isPublished" (click)="publish()" mat-raised-button><mat-icon>publish</mat-icon>Опубликовать</button>
      <button *ngIf="newsData.isPublished" (click)="unPublish()" mat-raised-button>Снять с публикации</button>
      <button mat-raised-button (click)="edit()"><mat-icon>edit</mat-icon>Редактировать</button>
      <button mat-raised-button (click)="deleteNewsRequested()" color="warn"><mat-icon>delete</mat-icon>Удалить</button>
  </mat-card-actions>
</div>
<app-news-editor
  [newsId]="newsData.id"
  [title]="newsData.title"
  [htmlData]="newsData.text"
  [isImportant]="newsData.isImportant"
*ngIf="isEditMode" (cancelled)="cancelEdit()" (saved)="saved($event)"></app-news-editor>
</mat-card>
<div class="spacer"></div>
