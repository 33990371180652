          <div class="formWrapper">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <mat-card>
            <mat-card-header>
              <mat-card-title>Авторизация</mat-card-title>
            </mat-card-header>
            <mat-error>{{error}}</mat-error>
              <div class="form-group">
                  <mat-form-field>
                    <input matInput type="text" placeholder="Имя пользователя" formControlName="eMail" class="form-control" />
                  </mat-form-field>
              </div>
              <div class="form-group">
                  <mat-form-field>
                    <input matInput placeholder="Пароль" type="password" formControlName="password" class="form-control"/>
                  </mat-form-field>
              </div>
              <div class="form-group">
                <a href="/login/restore">Восстановить пароль</a>
              </div>
              <button mat-button [disabled]="loading" class="btn btn-primary">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  Вход
              </button>
          </mat-card>
          </form>
        </div>
