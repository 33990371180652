import { Component, OnInit, Injectable, Inject, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { GoodService } from 'src/app/services/good.service';
import { GoodsHierarchyNodeModel } from 'src/app/models/goods-hierarchy/goods-hiererchy-node.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GoodType, SelectGoodsDialogModel } from 'src/app/models/select-goods-dialog.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NamedObject } from 'src/app/models/named-object.model';
import { tick } from '@angular/core/testing';
import { ProductsService } from 'src/app/services/products.service';

export class FileFlatNode {
  constructor(
     public expandable: boolean, public id: number, public name: string, public isChecked: boolean, public level: number) {}
}

@Component({
  templateUrl: './good-select-dialog.component.html',
  styleUrls: ['./good-select-dialog.component.scss']
})

export class GoodSelectDialogComponent implements OnInit {

  treeControl: FlatTreeControl<FileFlatNode>;
  treeFlattener: MatTreeFlattener<GoodsHierarchyNodeModel, FileFlatNode>;
  dataSource: MatTreeFlatDataSource<GoodsHierarchyNodeModel, FileFlatNode>;

  private _goodSelected: EventEmitter<NamedObject> = new EventEmitter();


  constructor(public dialogRef: MatDialogRef<GoodSelectDialogComponent>, goodsService: GoodService,
    productService: ProductsService,
    @Inject(MAT_DIALOG_DATA) public data: SelectGoodsDialogModel) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel,
      this._isExpandable, this._getChildren);
      this.treeControl = new FlatTreeControl<FileFlatNode>(this._getLevel, this._isExpandable);
      this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

      if (data.goodType == GoodType.Raw)
        goodsService.getHiererchy().subscribe(data => this.dataSource.data = data);
      else if (data.goodType == GoodType.Product)
        productService.getHiererchy().subscribe(data => this.dataSource.data = data);
  }

  transformer = (node: GoodsHierarchyNodeModel, level: number) => {
    return new FileFlatNode(!!node.childs, node.id, node.name, this.isCheched(node), level);
 }
 private _getLevel = (node: FileFlatNode) => node.level;
 private _isExpandable = (node: FileFlatNode) => node.expandable;
 private _getChildren = (node: GoodsHierarchyNodeModel): Observable<GoodsHierarchyNodeModel[]> => observableOf(node.childs);
 hasChild = (_: number, _nodeData: FileFlatNode) => _nodeData.expandable;

  ngOnInit(): void {
  }

  onNoClick() {
    this.dialogRef.close();
  }

  isCheched(node: GoodsHierarchyNodeModel) : boolean {
    if (node.type == 'group')
      return false;

    return this.data.selected.some(item => item.id == node.id);
  }

  onGoodNodeClick(node: FileFlatNode) {
    const item : NamedObject = {id: node.id, name: node.name};
    this._goodSelected.emit(item);
  }

  getResult(): NamedObject[] {
    return this.dataSource._flattenedData.value
    .filter(a => a.isChecked)
    .map(a => { return {id: a.id, name: a.name} });
  }

  /**
   * goodSelected
   */
  public goodSelected() : Observable<NamedObject> {
    return this._goodSelected;
  }

}
