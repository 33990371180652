<div mat-dialog-content>
  <p>{{data.name}}</p>
  <ul>
    <li>
      <mat-form-field>
        <input matInput [(ngModel)]="data.name" placeholder="Наименование металла">
      </mat-form-field>
    </li>
    <li>
      <mat-form-field>
        <textarea type="text" matInput [(ngModel)]="data.comment" placeholder="Комментарий" rows="4"></textarea>
      </mat-form-field>
    </li>
    <li>
      <mat-checkbox [(ngModel)]="data.inputPriceManual">Базовая цена вводится вручную.</mat-checkbox>
    </li>
    <li>
      <mat-form-field>
        <mat-select [(ngModel)]="data.baseGoodRule" [compareWith]="ruleComparisonFunction" placeholder="Рассчитывать цену от:">
          <mat-option *ngFor="let rule of goodsFromGroup" [value]="rule">{{rule?.goodName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </li>
    <li *ngIf="data.baseGoodRule">
      <mat-form-field>
        <input type="number"  matInput [(ngModel)]="data.baseGoodRule.multiplier" placeholder="Множитель">
      </mat-form-field>
    </li>
    <li *ngIf="data.baseGoodRule">
      <mat-form-field>
        <input type="number" matInput [(ngModel)]="data.baseGoodRule.add" placeholder="Слагаемое">
      </mat-form-field>
    </li>
  </ul>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Отмена</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>OK</button>
</div>
