<div mat-dialog-content>
  <mat-icon [svgIcon]="getContactIcon()"></mat-icon>
  <mat-form-field>
    <input matInput required [(ngModel)]="data.contactData" [placeholder]="data.contactTypeDisplayName">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-flat-button (click)="ok()" [disabled]="data.contactData.length==0">OK</button>
  <button mat-flat-button (click)="cancel()">Отмена</button>
</div>
