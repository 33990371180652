import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PictureViewDialogParamsModel } from 'src/app/models/common/picture-view-dialog-params.model';

@Component({
  templateUrl: './picture-view-modal.component.html',
  styleUrls: ['./picture-view-modal.component.scss']
})
export class PictureViewModalComponent implements OnInit {

  public currentPicture: string;

  constructor(private dialogRef: MatDialogRef<PictureViewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PictureViewDialogParamsModel ) { }

  ngOnInit() {
    this.currentPicture = this.data.pictures[0];
  }

  close() {
    this.dialogRef.close();
  }

}
