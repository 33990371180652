<div class="formWrapper">
<section fxLayout="row wrap" fxLayoutAlign="center center">
  <mat-card fxFlex="500px" fxFlex.xs="100%">
      <mat-card-title>Новый партнер</mat-card-title>
      <form [formGroup]="partnerForm" autocomplete="off" novalidate (ngSubmit)="createOwner(partnerForm.value)" fxLayout="column wrap"
            fxLayoutAlign="center center" fxLayoutGap="10px">
        <mat-card-content>
          <div class="form-group">
          <mat-form-field>
            <input matInput type="text" placeholder="Наименование" formControlName="name" id="name">
            <mat-hint align="end">Not more then 60 characters long.</mat-hint>
            <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
            <mat-error *ngIf="hasError('name', 'maxlength')">You have more than 60 characters</mat-error>
          </mat-form-field>
          </div>

          <div>
            <mat-form-field>
              <input matInput type="text" placeholder="ИНН" formControlName="taxNumber">
              <mat-hint align="end">Не более 12 символов</mat-hint>
              <mat-error *ngIf="hasError('taxNumber', 'maxlength')">Не более 12 символов</mat-error>
              <mat-error *ngIf="hasError('taxNumber', 'pattern')">Разрешены только цифры</mat-error>
              <mat-error *ngIf="hasError('taxNumber','taxNumber')">Существует партнер с этим ИНН: <a [href]="getConflictPartnerUrl()">{{conflictPartner.name}}</a></mat-error>
            </mat-form-field>
          </div>

          <div class="form-group">
          <mat-form-field>
            <input matInput type="text" placeholder="Адрес" formControlName="address">
            <mat-hint align="end">Не более 100 символов.</mat-hint>
            <mat-error *ngIf="hasError('address', 'required')">Address is required</mat-error>
            <mat-error *ngIf="hasError('address', 'maxlength')">You have more than 100 characters</mat-error>
          </mat-form-field>
          </div>
          <div class="form-group">
          <mat-form-field>
            <textarea matInput type="text" placeholder="Комментарий" formControlName="comment" rows="4"></textarea>
            <mat-hint align="end">Not more then 100 characters long.</mat-hint>
            <mat-error *ngIf="hasError('comment', 'maxlength')">You have more than 100 characters</mat-error>
          </mat-form-field>
          </div>
          <h2>Контакты</h2>
          <app-contacts-editor [contactData]="contacts"></app-contacts-editor>
        </mat-card-content>
        <mat-card-actions align="center">
          <button mat-raised-button color="primary" [disabled]="!partnerForm.valid">Create</button>
          <button type="button" mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
        </mat-card-actions>
      </form>
    </mat-card>
</section>
</div>
