import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss']
})
export class PasswordDialogComponent implements OnInit {

  public password: string = '';
  public passwordRepeat: string = '';

  constructor(private dialogRef: MatDialogRef<PasswordDialogComponent>) { }

  ngOnInit(): void {
  }

  onClose(): void {
    this.dialogRef.close();
  }

  isOk() : boolean {
    return this.password === this.passwordRepeat && this.password !== '';
  }

}
