<mat-card>
  <mat-card-header>
    <mat-card-title>
      Редактирование завода
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div>
    <mat-form-field>
      <input matInput [(ngModel)]="data.name" placeholder="Наименование" required>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <input matInput [(ngModel)]="data.address" placeholder="Адрес" required>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <input matInput [(ngModel)]="data.distance" placeholder="Расстояние">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
    <input matInput [(ngModel)]="data.minGarbage" placeholder="Мин. засор">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
    <input matInput [(ngModel)]="data.maxGarbage" placeholder="Макс. засор">
  </mat-form-field>
  </div>
  <div>
    <textarea matInput rows="5" [(ngModel)]="data.comment" placeholder="Комментарий"></textarea>
  </div>
  </mat-card-content>
  <mat-card-footer>
<mat-action-row>
  <mat-card-actions align="center">
    <button mat-raised-button color="primary" (click)="ok(data)">OK</button>
    <button type="button" mat-raised-button color="warn" (click)="onCancel()">Отмена</button>
  </mat-card-actions>
</mat-action-row>
</mat-card-footer>
</mat-card>
