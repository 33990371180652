import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './enviroment.service';
import { FactoryModel } from '../models/factory.model';
import { Observable } from 'rxjs';
import { AbstractCrudService } from './abstract-crud-service';

@Injectable({
  providedIn: 'root'
})
export class FactoryService extends AbstractCrudService<FactoryModel> {

  constructor(http: HttpClient, environmentService: EnvironmentService) {
    super(http, environmentService.getValue('apiUrl', '') +  '/factories');
  }

  public getFactories(): Observable<FactoryModel[]> {
    return this.http.get<FactoryModel[]>(this.serviceUrl);
  }
}


