import { Component } from '@angular/core';
import { HeaderComponent } from './navigation/header/header.component'
import { UserSession } from './models/user-session';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'vms-prices';
  currentSession: UserSession;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService) {
    this.authenticationService.currentSession.subscribe(x => this.currentSession = x);
}


}
