import { Component, Input, OnInit } from '@angular/core';
import { ProductPicture } from 'src/app/models/products/product-picture';
import { PicturesService } from 'src/app/services/pictures.service';

@Component({
  selector: 'app-product-pictures-edit',
  templateUrl: './product-pictures-edit.component.html',
  styleUrls: ['./product-pictures-edit.component.scss']
})
export class ProductPicturesEditComponent implements OnInit {

  @Input()
  public pictures: ProductPicture[] = [];

  constructor(private picturesService: PicturesService) { }

  ngOnInit() {
  }

  onFileSelected(event) {
    const file : File = event.target.files[0];

    if (file) {

      var pic = <ProductPicture>{id: null};

      this.pictures.push(pic);

      this.picturesService.upload(file)
      .subscribe(pictureId => {
        var pics = this.pictures;
        pic.pictureId = pictureId
      });
    }
  }

  deleteImage(pictureId: number) {
    var index = this.pictures.findIndex(a => a.pictureId == pictureId);
    this.pictures.splice(index, 1);
  }

}
