<h1 mat-dialog-title>{{data.goodName}}</h1>
<div mat-dialog-content>
  <mat-form-field>
    <input type="number" placeholder="Базовая цена" matInput [(ngModel)]="data.basePrice">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Отмена</button>
  <button mat-button [mat-dialog-close]="data.basePrice" cdkFocusInitial>OK</button>
</div>
