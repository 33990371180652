import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShipmentModel } from '../models/shipment/shipment.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ShipmentEditModel } from '../models/shipment/shipment-edit.model';

@Injectable({
  providedIn: 'root'
})
export class ShipmentsService {

  constructor(private http: HttpClient) { }

  getShipments(dateFrom: Date, dateTo: Date, partnerId? : number, factoryId?: number) : Observable<ShipmentModel[]> {
    let partnerIdFilter = '';
    if (partnerId != null)
    {
      partnerIdFilter = `partnerId=${partnerId}`;
    }

    let addressIdFilter = '';
    if (factoryId != null) {
      addressIdFilter = `factoryId=${factoryId}`;
    }

    let dateFromFilter = '';
    if (dateFrom != null) {
      dateFromFilter = `dateFrom=${dateFrom.toISOString()}`;
    }

    let dateToFilter = '';
    if (dateTo != null) {
      dateToFilter = `dateTo=${dateTo.toISOString()}`;
    }

    var filters = [dateFromFilter, dateToFilter, partnerIdFilter, addressIdFilter];

    filters = filters
        .filter(a => a.length > 0);

    var filtersStr = '';
    if (filters.length > 0) {
      filtersStr = filters.reduce((a,b)=> a + '&' + b);
    }

    return this.http.get<ShipmentModel[]>(`${environment.apiUrl}/Shipments?${filtersStr}`);
  }

  addShipment(shipment: ShipmentEditModel) : Observable<ShipmentEditModel> {
    return this.http.post<ShipmentEditModel>(`${environment.apiUrl}/Shipments/add`, shipment);
  }

  get(id: number) : Observable<ShipmentEditModel> {
    return this.http.get<ShipmentEditModel>(`${environment.apiUrl}/Shipments/${id}`);
  }

  updateShipment(id: number, shipment: ShipmentEditModel) : Observable<any> {
    return this.http.put(`${environment.apiUrl}/Shipments/${id}`, shipment);
  }

  delete(id: number) : Observable<any> {
    return this.http.delete(`${environment.apiUrl}/Shipments/${id}/delete`);
  }
}
