import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-product-picture-item-view',
  templateUrl: './product-picture-item-view.component.html',
  styleUrls: ['./product-picture-item-view.component.scss']
})
export class ProductPictureItemViewComponent implements OnInit {

  constructor(private dialogService: DialogService) { }

  @Input()
  public pictureId?: number;

  @Output()
  onImageDelete = new EventEmitter();

  ngOnInit() {
  }

  public getImagePath(): string {
    return `api/Pictures/${this.pictureId}`;
  }

  public deleteImage() {

    this.dialogService.yesNoQuestion("Удалить изображение?", "")
    .subscribe(r => {
      if (r)
        this.onImageDelete.emit(this.pictureId);
    });

  }

  public viewImage(pictureId: number) {
    this.dialogService.showGalery([`api/Pictures/${pictureId}`]);
  }

}
