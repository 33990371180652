
<div>
  <div class="CatalogLayout">
    <div class="header"><h1 class="Heading Heading_level_1 Heading_bold ">Каталог</h1></div>
    <app-product-groups (groupChanged)="groupChanged($event)"></app-product-groups>
  </div>

  <app-product-list [products]="products" [pagedData]="pagedData" [parentId]="parentId">

  </app-product-list>

  <div class="d-flex justify-content-center">
    <pagination-controls id="products"
      previousLabel="Назад"
      nextLabel="Далее"
      (pageChange)="handlerPageChange($event)"
    >

    </pagination-controls>
  </div>


</div>
