<div class="formWrapper">
  <mat-card>
    <mat-card-title>
        Смена пароля
    </mat-card-title>
    <form *ngIf="error.length == 0" [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <mat-hint> Пользователь: {{userName}} </mat-hint>
      </div>
      <div class="form-group">
        <mat-form-field>
          <input matInput type="password" formControlName="newPassword" placeholder="Новый пароль">
          <mat-error *ngIf="hasError('newPassword', 'required')">Заполнение поля обязательно</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field>
        <input matInput type="password" formControlName="newPasswordRepeat" placeholder="Повторите пароль" required>
        <mat-error *ngIf="hasError('newPasswordRepeat', 'required')">Заполнение поля обязательно</mat-error>
      </mat-form-field>
      <mat-error *ngIf="!isValid()">Пароли не совпадают</mat-error>
      <mat-card-actions>
        <button mat-raised-button color="primary">Отправить</button>
      </mat-card-actions>
    </form>
    <mat-error *ngIf="error">{{error}}</mat-error>
  </mat-card>

</div>
