import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { PartnerFactoryModel } from 'src/app/models/partners/partner-factory.model';
import { DialogService } from 'src/app/services/dialog.service';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-factories-list',
  templateUrl: './factories-list.component.html',
  styleUrls: ['./factories-list.component.scss']
})
export class FactoriesListComponent implements OnInit {

  @Input()
  @Output()
  factories: PartnerFactoryModel[] = [];

  @ViewChild('table') table: MatTable<PartnerFactoryModel>;

  tableColumns = ['name','address', 'actions'];

  constructor(private dialogService: DialogService) { }

  ngOnInit(): void {
  }

  deleteFactory(factory: PartnerFactoryModel) {

    this.dialogService.yesNoQuestion('Удалить завод из списка?')
      .subscribe(result => {
        if (result) {
          const index = this.factories.indexOf(factory, 0);
          delete this.factories[index];
          this.factories.splice(index, 1);
          this.table.renderRows();
        }
      });

  }

  addFactory() {
    this.dialogService.selectFactory(this.factories.map(a => a.id))
    .subscribe(result => {
      if (result != null) {
        this.factories.push({
          id: result.id,
          name: result.name,
          address: result.address
        });
        this.table.renderRows();
      }
    });
  }

}
