<h3>
Отгрузка
</h3>
<mat-dialog-content>
    <mat-tab-group>
      <mat-tab label="Отгрузка">
        <div class="fields">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Дата отгрузки:</mat-label>
            <input matInput [matDatepicker]="pickerDate" [(ngModel)]="shipment.shipmentDate" required>
            <mat-datepicker-toggle matSuffix [for]="pickerDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerDate></mat-datepicker>
            <mat-error>Введите корректную дату</mat-error>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field>
            <mat-select placeholder="Партнер" name="partner" [(ngModel)]="shipment.partnerId" required (selectionChange)="partnerChange(shipment.partnerId)" >
              <mat-option *ngFor="let partner of partners" [value]="partner?.id">{{partner?.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-select placeholder="Завод" required [(ngModel)]="shipment.factoryId">
              <mat-option *ngFor="let factory of factories" [value]="factory?.id">{{factory?.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field>
            <textarea matInput type="text" [(ngModel)]="shipment.comment" placeholder="Комментарий" cdkTextareaAutosize
            cdkAutosizeMinRows="6"> </textarea>
          </mat-form-field>
        </div>
      </div>
      </mat-tab>
      <mat-tab label="Фотографии">
        <button mat-icon-button (click)="uploadFile()"><mat-icon>add</mat-icon></button>
        <div class="progress form-group" *ngIf="progress > 0">
          <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="progress">
          </div>
        </div>
        <input #FileSelectInputDialog type="file" accept="image/*" multiple hidden="true" (change)="onFilesSelected($event)"/>
        <table>
          <tr *ngFor="let fileId of shipment.pictures">
            <td><a [href]="getPicturePath(fileId)" target="_blank"><img [src]="getPictureThumbnailPath(fileId)"></a> </td>
            <td><button mat-mini-fab (click)="deletePicture(fileId)"><mat-icon>delete</mat-icon></button></td>
          </tr>
        </table>
      </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="shipment" cdkFocusInitial [disabled]="!isDataValid()">OK</button>
  <button mat-raised-button color="warn" (click)="dialogRef.close()">Отмена</button>
</mat-dialog-actions>
