<h1 mat-dialog-title>{{data.name}}</h1>

<ul>
  <li>
<mat-form-field>
  <input type="number" matInput [(ngModel)]="data.price" placeholder="Цена за тонну">
</mat-form-field>
  </li>
  <li>
<mat-form-field>
  <mat-select [(ngModel)]="data.currency" [compareWith]="currencyComparisonFunction"  placeholder="Валюта:" >
    <mat-option *ngFor="let currency of currencies" [value]="currency">{{currency?.name}} ({{currency.code}})</mat-option>
  </mat-select>
  <div *ngIf="data.currency==null" class="field-error">Не выбрана валюта</div>
</mat-form-field>
</li>
<li>
<mat-form-field>
  <input #validThru matInput [matDatepicker]="picker" [(ngModel)]="data.validThru" required placeholder="Действует до">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-error>Не выбран срок действия цены</mat-error>
</mat-form-field>
</li>
</ul>
<div mat-dialog-actions>
  <button mat-button (click)="onClose()" cdkFocusInitial>Отмена</button>
  <button mat-button (click)="onOk()" [disabled]="!isValid()" [mat-dialog-close]="data">OK</button>
</div>
