import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NamedObject } from 'src/app/models/named-object.model';
import { DictionariesService } from 'src/app/services/dictionaries.service';
import { PicturesService } from 'src/app/services/pictures.service';
import { environment } from 'src/environments/environment';
import { ShipmentEditModel } from 'src/app/models/shipment/shipment-edit.model';
import { PartnerService } from 'src/app/services/partner.service';
import { Observable } from 'rxjs';
import { PartnerFactoryModel } from 'src/app/models/partners/partner-factory.model';

@Component({
  templateUrl: './edit-shipment.component.html',
  styleUrls: ['./edit-shipment.component.scss']
})

export class EditShipmentComponent implements OnInit {

  @ViewChild('FileSelectInputDialog') FileSelectInputDialog: ElementRef;

  partners: NamedObject[] = []
  progress: number;
  factories: PartnerFactoryModel[] = [];

  constructor(public dialogRef: MatDialogRef<EditShipmentComponent>,
    @Inject(MAT_DIALOG_DATA) public shipment: ShipmentEditModel, private dictionaryService: DictionariesService,
    private partnersService: PartnerService,
    private picturesService: PicturesService) {
      this.partnerChange(shipment.partnerId);
     }

  ngOnInit(): void {
    this.dictionaryService.getBuyers().subscribe(result => {
      this.partners = [null, ... result];
    });
  }

  uploadFile() {
    const e: HTMLElement = this.FileSelectInputDialog.nativeElement;
    e.click();
  }

  onFilesSelected(event: any) {
    const file = (event.target as HTMLInputElement).files[0];
    this.doUploadFile(file);
    event = null;
 }

 doUploadFile(file: File) {
  this.picturesService.upload(
    file
  ).subscribe(fileId => {
    this.shipment.pictures.push(fileId);
  });
}

deletePicture(fileId: number) {
  this.shipment.pictures = this.shipment.pictures.filter(a => a != fileId);
}

getPictureThumbnailPath(id: number) {
  return `${environment.apiUrl}/Pictures/${id}/thumbnail`
}

getPicturePath(id: number) {
  return `${environment.apiUrl}/Pictures/${id}`
}

openBigPicture(fileId: number) {
 var path = this.getPicturePath(fileId);
 document.open(path);
}

partnerChange(partnerId: number) {
  this.factories = [];
  this.partnersService.getFactories(partnerId).subscribe(result => {
    this.factories = [null, ...result];
  });
}

getFactories(id: number) : Observable<PartnerFactoryModel[]> {
  return this.partnersService.getFactories(id);
}

isDataValid() : boolean {
  return this.shipment.factoryId != null && this.shipment.partnerId != null && this.shipment.shipmentDate != null;
}

}
