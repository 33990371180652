<mat-nav-list>
  <a mat-list-item routerLink="/home" (click)="onSidenavClose()">
      <mat-icon>home</mat-icon> <span class="nav-caption">Home</span>
  </a>
  <a mat-list-item routerLink="/partners" (click)="onSidenavClose()">
      <mat-icon>assignment_ind</mat-icon> <span class="nav-caption">Партнеры</span>
  </a>
  <a mat-list-item routerLink="/prices" (click)="onSidenavClose()">
      <mat-icon>money</mat-icon><span class="nav-caption">Цены</span>
  </a>
  <a mat-list-item routerLink="/shipments" (click)="onSidenavClose()">
    <mat-icon>money</mat-icon><span class="nav-caption">Отгрузки</span>
</a>
</mat-nav-list>
