import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SurchargeEditData } from 'src/app/models/surcharge-edit.model';
import { PricesService } from 'src/app/services/prices.service';
import { PriceModel } from 'src/app/models/price.model';

@Component({
  templateUrl: './surcharge-edit-modal.component.html',
  styleUrls: ['./surcharge-edit-modal.component.scss']
})
export class SurchargeEditModalComponent implements OnInit {

  public price: PriceModel;

  constructor(public dialogRef: MatDialogRef<SurchargeEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SurchargeEditData, public pricesService: PricesService ) {

    }

    recalcPrice() : void
    {
      this.pricesService.calcPrice(this.data.goodId, this.data.priceId, this.data.surcharge)
      .subscribe(price => {
        this.price = price;
      });
    }

  ngOnInit(): void {
    this.recalcPrice();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
