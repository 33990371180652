import { Component, OnInit, Inject } from '@angular/core';
import { GoodEditModel, BaseGoodRuleModel } from 'src/app/models/good-edit.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GoodService } from 'src/app/services/good.service';
import { NamedObject } from 'src/app/models/named-object.model';
import { DictionariesService } from 'src/app/services/dictionaries.service';

@Component({
  templateUrl: './good-edit-dialog.component.html',
  styleUrls: ['./good-edit-dialog.component.scss']
})
export class GoodEditDialogComponent implements OnInit {

  public currencies: NamedObject[] = [];

  goodsFromGroup: BaseGoodRuleModel[] = [];

  constructor(public dialogRef: MatDialogRef<GoodEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GoodEditModel, private goodsService: GoodService,
    private dicrionariesService: DictionariesService) { }

  ngOnInit(): void {
    this.goodsService.getGoodsByGroup(this.data.groupId).subscribe(resut => {

      let goods: NamedObject[];

      if (this.data.id != null)
        goods = resut.filter(a => a.id != this.data.id);
      else
        goods = resut;

      this.goodsFromGroup = goods.map(a => { return {goodId: a.id, goodName: a.name, multiplier: 1.0, add: 0};});
      this.goodsFromGroup = [null, ...this.goodsFromGroup];
    });

    this.dicrionariesService.getCurrencies().subscribe(result => {
      this.currencies = result;
    })
  }

  public ruleComparisonFunction = function( option, value ) : boolean {
    return option?.goodId === value?.goodId;
  }

  public currencyComparisonFunction = function( option, value ) : boolean {
    return option.id === value.id;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}
