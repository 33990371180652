import { NamedObject } from './named-object.model';

export interface SelectGoodsDialogModel {
  selected: NamedObject[];
  goodType: GoodType;
}

export enum GoodType {
  Raw, Product
}
