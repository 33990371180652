import { Component, Input, OnInit } from '@angular/core';
import { ProductPriceViewModel } from 'src/app/models/product-edit-model';

@Component({
  selector: 'app-product-item-view',
  templateUrl: './product-item-view.component.html',
  styleUrls: ['./product-item-view.component.scss']
})
export class ProductItemViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  @Input()
  public product: ProductPriceViewModel;

  getPictureIds() {
    return this.product.pictures;
  }

}
