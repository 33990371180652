import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './price-list.component.html',
  styleUrls: ['./price-list.component.scss']
})
export class PriceListComponent implements OnInit {

  url: string;
  show: boolean = false;
  priceId: string;

  reportHtml : string = '';

  constructor(private activeRoute: ActivatedRoute, private http: HttpClient) { }

  ngOnInit(): void {
    this.priceId = this.activeRoute.snapshot.params['priceId'];

  }

  Clicked() {
    this.show = true;
    this.http.get(`api/Prices/buildPrice?priceId=${this.priceId}`, {responseType: 'text'})
    .subscribe(html => {
      this.reportHtml = html.toString();
    }, error => {
      console.log(error);
    });
    }
}
