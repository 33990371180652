import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './enviroment.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  private currencySigns: Map<string, string> = new Map([['USD', '$'], ['EUR', '€'], ['RUR', '₽']]);
  private serviceUrl = this.environmentService.getValue('apiUrl', '') + '/Prices';

  constructor(private http: HttpClient, private environmentService: EnvironmentService) { }

  public getSign(code: string) : string {

    if (this.currencySigns.has(code))
      return this.currencySigns.get(code);

    return code;
  }

    /**
   * GetCurrencyCource
   */
  public GetCource(code: string): Observable<number> {
    return this.http.get<number>(`${this.serviceUrl}/currencyCource?code=${code}`)
  }
}
