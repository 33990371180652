<div class="list">
  <div>
    <a [routerLink]="['/products/create']" [queryParams]="{ parentId: parentId }" mat-button color="primary">
      <mat-icon>add</mat-icon>
      Создать</a>
  </div>
  <section>
    <app-product-item-view *ngFor="let item of products | paginate: {
      id: 'products',
      itemsPerPage: pagedData.pageSize,
      currentPage: pagedData.pageNumber,
      totalItems: pagedData.totalCount
    };
    let i = index" [product]="item"></app-product-item-view>
  </section>
</div>
