<div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="addComponentRaw()">
      Сырьё
    </button>
    <button mat-menu-item (click)="addComponent()">
      Комплектующие
    </button>
  </mat-menu>
  <button mat-mini-fab [matMenuTriggerFor]="menu">
    <mat-icon>add</mat-icon>
  </button>
  <mat-table [dataSource]="components" #componentsTable>
    <ng-container matColumnDef="icon">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let item">
        <img *ngIf="item.type=='component'" src="assets/device.png">
        <img *ngIf="item.type=='raw'" src="assets/raw-recycled.png">
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>Наименование</mat-header-cell>
      <mat-cell class="nameCell" *matCellDef="let item">{{item.name}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="percentage">
      <mat-header-cell *matHeaderCellDef>Содержание, %</mat-header-cell>
      <mat-cell *matCellDef="let item">
        <app-editable-value [value]="item.percentage" (onChange)="percentsChanged(item, $event)"></app-editable-value>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef >  </mat-header-cell>
      <mat-cell *matCellDef="let component">
        <button mat-mini-fab (click)="deleteComponent(component)" >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="['icon', 'name', 'percentage', 'actions']"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['icon', 'name', 'percentage', 'actions'];" ></mat-row>
  </mat-table>
  <div *ngIf="invalidPercents" class="percents-warning">
    <mat-icon class="warn">warning</mat-icon>
    Общее процентное содержание компонентов не может превышать 100%
    <mat-icon class="warn">warning</mat-icon>
  </div>
</div>
