import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { UserEditDialogComponent } from '../modals/user-edit-dialog/user-edit-dialog.component';
import { PasswordDialogComponent } from '../modals/password-dialog/password-dialog.component';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users: User[] = [];
  filteredUsers: User[] = [];
  searchString: string = '';

  constructor(private authService: AuthenticationService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.authService.getUsers().subscribe(result => {
      this.users = result;
      this.filteredUsers = this.users;
    });
  }

  filter() {
    this.filteredUsers = this.users.filter(user =>
      user.fullName.toUpperCase().search(this.searchString.toUpperCase()) >= 0
      || user.eMail.toUpperCase().search(this.searchString.toUpperCase()) >= 0)
  }

  editUser(user: User) {

    const dialogRef =  this.dialog.open(UserEditDialogComponent, {data: { ...user}});

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.authService.updateUser(result).subscribe(updatedUser => {

          if (updatedUser != null) {
            let oldUser = this.users.find(a=>a.id == user.id);

            oldUser.fullName = updatedUser.fullName;
            oldUser.eMail = updatedUser.eMail;
            oldUser.isActive = updatedUser.isActive;
            oldUser.isAdmin = updatedUser.isAdmin;
          }

        });
      }
    });

  }

  addUser() : void {
    let user: User = {fullName: '', eMail: '', isActive: true, isAdmin: false };
    const dialogRef =  this.dialog.open(UserEditDialogComponent, {data: { ...user}});
    dialogRef.afterClosed().subscribe(result => {
      if (result != null){
        this.authService.addUser(result).subscribe(createdUser => {
          this.users.push(createdUser);
          this.filter();
        });
      }
    });
  }

  changePassword(user: User) {
    const dialogRef = this.dialog.open(PasswordDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.authService.changePassword(user.id, result).subscribe();
      }
    });
  }


}
