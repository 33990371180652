import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  templateUrl: './cnange-password.component.html',
  styleUrls: ['./cnange-password.component.scss']
})
export class CnangePasswordComponent implements OnInit {

  public key: string;
  public userName: string = '';
  error: string = '';

  newPassword: string;
  newPasswordRepeat: string;
  passwordForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService) { }

  ngOnInit(): void {

    this.passwordForm = this.formBuilder.group({
      newPassword: ['', Validators.required],
      newPasswordRepeat: ['', Validators.required]
  });

    this.key = this.activeRoute.snapshot.params['key'];

    this.authenticationService.checkRestorePasswordRequest(this.key).subscribe(result => {
      this.userName = result.userName;
      if (!result.isOk)
      {
        this.error = 'Некорректный запрос, либо истек срок действия ссылки';
      }
    }, error => {
      this.error = 'Ошибка при обращении к серверу';
    });
  }

  get f() { return this.passwordForm.controls; }

  isValid() : boolean {
    const newPass = this.getNewPassword();
    const newPassRepeat = this.getNewPasswordRepeat();

    return newPass == newPassRepeat;
  }

  getNewPassword() : string {
    return this.passwordForm.get("newPassword").value;
  }

  getNewPasswordRepeat() : string {
    return this.passwordForm.get("newPasswordRepeat").value;
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.passwordForm.controls[controlName].hasError(errorName);
  }

  onSubmit() {
    this.authenticationService.changePasswordByRequest(this.key, this.getNewPassword()).subscribe(result => {
      this.snackBar.open('Пароль успешно изменен!', null,  {duration: 5000});
      this.router.navigate(['/login']);
    }, error => {
      this.error = 'Ошибка';
    });
  }

}
