import { Component, OnInit } from '@angular/core';
import { IPagedData } from 'src/app/models/common/paged-data';
import { ProductPriceViewModel } from 'src/app/models/product-edit-model';
import { UserSession } from 'src/app/models/user-session';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ProductsService } from 'src/app/services/products.service';
import { Location } from '@angular/common'
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-prices-page',
  templateUrl: './product-prices-page.component.html',
  styleUrls: ['./product-prices-page.component.scss']
})
export class ProductPricesPageComponent implements OnInit {

    public products: ProductPriceViewModel[] = [];
    public pagedData: IPagedData;

  displayedColumns = ['name'];

  public currentPage: number = 1;
  private pageSize: number = 5;

  public parentId?: number;

  currentSession: UserSession;

  constructor(private router: Router, private location: Location, private authenticationService: AuthenticationService, private productsService: ProductsService) { }

  ngOnInit() {
    this.authenticationService.currentSession.subscribe(x => this.currentSession = x);

    this.reload();

  }

  private reload() {
    this.productsService.getPaged(this.currentPage, this.pageSize, this.parentId)
    .subscribe(result => {
      this.pagedData = result;
      this.products = result.data;
      this.pageSize = result.pageSize;
     // window.history.pushState({}, '', `/product-prices?parentId=${this.parentId}`);
      //this.router.navigate(['product-prices'])
      //this.location. replaceState(`/product-prices?parentId=${this.parentId}`)
    });
  }

  public groupChanged(parentId?: number) {
    this.parentId = parentId;
    this.reload();
  }

  public editProduct(productId: number) {

  }

  public deleteProduct(productId: number) {

  }

  public handlerPageChange(page: number) {
    this.currentPage = page;
    this.reload();
  }

}
