<h1 mat-dialog-title>Параметры расчета цен</h1>

<ul>
  <li>
<mat-form-field>
  <input type="number" matInput [(ngModel)]="data.cash" placeholder="Кеш">
</mat-form-field>
</li>
<li>
<mat-form-field>
  <input type="number" matInput [(ngModel)]="data.transport" placeholder="Транспорт">
</mat-form-field>
</li>
</ul>
<div mat-dialog-actions>
  <button mat-button (click)="onClose()" cdkFocusInitial>Отмена</button>
  <button mat-button [mat-dialog-close]="data">OK</button>
</div>
