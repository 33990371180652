import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PartnerFactoriesModalData } from './patner-factories-modal-data';
import { FactoryService } from 'src/app/services/factory.service';
import { FactoryModel } from 'src/app/models/factory.model';

@Component({
  templateUrl: './partner-factories-modal.component.html',
  styleUrls: ['./partner-factories-modal.component.scss']
})
export class PartnerFactoriesModalComponent implements OnInit {

  factories: FactoryModel[] = [];

  constructor(private dialogRef: MatDialogRef<PartnerFactoriesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PartnerFactoriesModalData, private service: FactoryService) { }


  ngOnInit(): void {
    this.service.getFactories()
    .subscribe(result => {
      this.factories = result;
    });
  }

  select(factory: FactoryModel) {
    this.dialogRef.close(factory);
  }

  close() {
    this.dialogRef.close();
  }

  isFactoryDisabled(factoryId: number) {
    return this.data.disableList.some(a => a == factoryId);
  }

}
