import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NewsViewModel } from 'src/app/models/news-view-model';
import { NewsEditModel } from 'src/app/models/news/news-edit-model';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-news-view',
  templateUrl: './news-view.component.html',
  styleUrls: ['./news-view.component.scss']
})
export class NewsViewComponent implements OnInit {

  @Input()
  newsData : NewsViewModel;

  @Input()
  userHasEditRights: boolean = false;

  @Output()
  deleteNews = new EventEmitter<NewsViewModel>();

  @Output()
  publishNews = new EventEmitter<NewsViewModel>();

  @Output()
  unPublishNews = new EventEmitter<NewsViewModel>();

  isEditMode: boolean = false;

  constructor(private dialogService: DialogService) { }

  ngOnInit(): void {
  }

  edit() {
    this.isEditMode = true;
  }

  cancelEdit() {
    this.isEditMode = false;
  }

  saved(news: NewsEditModel) {
      this.newsData.text = news.text;
      this.newsData.title = news.title;
      this.newsData.isImportant = news.isImportant;
      this.cancelEdit();
  }

  deleteNewsRequested() {
    this.dialogService.yesNoQuestion("Удалить новость?")
    .subscribe(result => {
      if (result) {
        this.deleteNews.emit(this.newsData);
      }
    });
  }

  publish() {
    this.publishNews.emit(this.newsData);
  }

  unPublish() {
    this.dialogService.yesNoQuestion("Снять новость с публикации?")
    .subscribe(result => {
      if (result) {
        this.unPublishNews.emit(this.newsData);
      }
    });
  }
}
