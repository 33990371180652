import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { GoodSelectDialogComponent } from 'src/app/modals/common.dialogs/good-select-dialog/good-select-dialog.component';
import { NamedObject } from 'src/app/models/named-object.model';
import { ComponentType, ProductComponent, ProductComponentAddingEvent } from 'src/app/models/product-edit-model';
import { GoodType } from 'src/app/models/select-goods-dialog.model';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-product-components-edit',
  templateUrl: './product-components-edit.component.html',
  styleUrls: ['./product-components-edit.component.scss']
})
export class ProductComponentsEditComponent implements OnInit {

  @ViewChild('componentsTable') table : MatTable<ProductComponent>;

  @Input()
  public components: ProductComponent[];

  @Output()
  public onComponentAdding: EventEmitter<ProductComponentAddingEvent> = new EventEmitter();

  invalidPercents: boolean = false;

  constructor(private dialog: MatDialog, private dialogService: DialogService) { }

  ngOnInit() {
    this.checkPercents();
  }

  private addComponentRaw1(good: NamedObject, componentType: ComponentType) {
    this.components.push({id: good.id, type: componentType, name: good.name, percentage: 0 });
    this.table.renderRows();
  }

  selectGoods(goodType: GoodType, componentType: ComponentType) {

    const dialogRef = this.dialog.open(GoodSelectDialogComponent, {
      data: {
        selected: this.components
        .filter(a => a.type == componentType)
        .map(s => <NamedObject>{ id: s.id, name: s.name }),
        goodType: goodType
      }
    });
    dialogRef.componentInstance.goodSelected().subscribe(good => {

      if (this.components.some(a => a.id == good.id && a.type == componentType))
        return;

      var evt = <ProductComponentAddingEvent> {componentType: componentType, componentId: good.id, cancel: false};

      this.onComponentAdding.emit(evt);

      if (evt.cancel)
        return;

      this.addComponentRaw1(good, componentType);
    });
  }

  addComponentRaw() {
    this.selectGoods(GoodType.Raw, ComponentType.Raw);
  }

  addComponent() {
    this.selectGoods(GoodType.Product, ComponentType.Component);
  }

  deleteComponent(component: ProductComponent) {
    this.dialogService.yesNoQuestion("Удалить компонент?")
    .subscribe(result => {
      if (result) {
        var index = this.components.indexOf(component);
        this.components.splice(index, 1);
        this.table.renderRows();
      }
    });
  }

  percentsChanged(item: ProductComponent, newPercents: number) {
    item.percentage = newPercents;
    this.checkPercents();
  }

  private checkPercents() {
    var sum = this.components.reduce((sum, current) => sum + current.percentage, 0);
    console.debug(sum);
    this.invalidPercents = sum > 100;
  }
}
