import { Component, OnInit, Inject } from '@angular/core';
import { EditBasePriceManualModel } from 'src/app/models/edit-base-price-manual.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './edit-base-price-manual-dialog.component.html',
  styleUrls: ['./edit-base-price-manual-dialog.component.scss']
})
export class EditBasePriceManualDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EditBasePriceManualDialogComponent>,
    @Inject(MAT_DIALOG_DATA)public data: EditBasePriceManualModel) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
