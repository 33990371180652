<div class="formWrapper">
  <form (ngSubmit)="submit()" novalidate [formGroup]="restoreForm">
    <mat-card>
      <mat-card-title>Восстановление пароля</mat-card-title>
      <div *ngIf="!isDone">
      <div class="form-group">
        <mat-form-field>
          <input matInput placeholder="E-Mail" formControlName="eMail">
          <mat-error *ngIf="hasError('eMail', 'required')">Заполнение поля обязательно</mat-error>
          <mat-error *ngIf="hasError('eMail', 'email')">Вы ввели некорректный E-Mail</mat-error>
        </mat-form-field>
      </div>
        <mat-error *ngIf="error">{{error}}</mat-error>
      </div>
      <mat-card-actions>
        <button *ngIf="!isDone" mat-raised-button color="primary" [disabled]="!restoreForm.valid">Отправить</button>
        <button mat-raised-button color="warn" (click)="back()">Назад</button>
      </mat-card-actions>

    <div *ngIf="isDone">
      <mat-hint>Отправлено</mat-hint>
    </div>
    </mat-card>
  </form>
</div>
