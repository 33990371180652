<mat-toolbar color="primary">
  <div fxHide.gt-xs>
      <button mat-icon-button (click)="onToggleSidenav()">
          <mat-icon>menu</mat-icon>
      </button>
  </div>

    <a mat-button routerLink="/">Главная</a>
    <a mat-button [routerLink]="'/partners'"> Покупатели </a>
    <a mat-button [routerLink]="'/prices'"> Цены сырья </a>
    <a mat-button [routerLink]="'/products-prices'"> Цены изделий </a>
    <a mat-button [routerLink]="'/shipments'"> Отгрузки </a>
    <a mat-button [routerLink]="'/factories'"> Заводы </a>

    <span class = "filler"></span>
    <button *ngIf="currentSession?.isAdmin" [matMenuTriggerFor]="settingsMenu" mat-button>
    <mat-icon>settings</mat-icon>
    </button>
    <mat-menu #settingsMenu="matMenu" [overlapTrigger]="false">
      <button mat-button [routerLink]="'admin/users'" ><mat-icon>supervisor_account</mat-icon>Пользователи</button>
    </mat-menu>
    <button *ngIf="currentSession" mat-button (click)="showNotifications($event)">
      <mat-icon [matBadge]="notificationsCount" [matBadgeHidden]="notificationsCount==0" matBadgeColor="warn">notifications</mat-icon>
    </button>
    <button *ngIf="currentSession" mat-button [matMenuTriggerFor]="menu">
      <mat-icon>account_circle</mat-icon>
      {{currentSession.userName}}</button>
    <mat-menu #menu="matMenu" [overlapTrigger]="false">
      <button mat-menu-item (click)="logout()">Выход</button>
    </mat-menu>

</mat-toolbar>
<app-notifications-menu (notificationSelected)="notificationSelected($event)">

</app-notifications-menu>
