import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedData } from '../models/common/paged-data';
import { GoodsHierarchyNodeModel } from '../models/goods-hierarchy/goods-hiererchy-node.model';
import { ProductEditModel, ProductPriceViewModel } from '../models/product-edit-model';
import { GroupHierarchyResult } from '../models/products/group-hierarchy-result';
import { ProductGroup } from '../models/products/product-group';
import { AbstractCrudService } from './abstract-crud-service';
import { EnvironmentService } from './enviroment.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService extends AbstractCrudService<ProductEditModel> {

constructor(http: HttpClient, environmentService: EnvironmentService) {
  super(http, environmentService.getValue('apiUrl', '') +  '/Products');
}

public getPaged(pageNumber: number, pageSize: number, parentId?: number) : Observable<PagedData<ProductPriceViewModel>> {
  return this.http.get<PagedData<ProductPriceViewModel>>(`${this.serviceUrl}?PageIndex=${pageNumber}&PageSize=${pageSize}&ParentId=${parentId ?? ''}`);
}

public get(id: number) : Observable<ProductEditModel> {
  return this.http.get<ProductEditModel>(`${this.serviceUrl}/${id}`);
}

public addGroup(groupName: string, parentId?: number) : Observable<ProductGroup> {
  return this.http.post<ProductGroup>(`${this.serviceUrl}/groups/add`, { name: groupName, parentId: parentId});
}

public getGroups(parentId?: number) : Observable<GroupHierarchyResult> {
  return this.http.get<GroupHierarchyResult>(`${this.serviceUrl}/groups?ParentId=${parentId ?? ''}`);
}

public getHiererchy() : Observable<GoodsHierarchyNodeModel[]> {
  return this.http.get<GoodsHierarchyNodeModel[]>(this.serviceUrl + '/hiererchy');
}

}
